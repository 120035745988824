@import './libs/veneer/src/styles/sass/application';

.container {
  font-size: 13px;
  line-height: 1;

  @include on-screen(large) {
    font-size: 0.75rem;
    font-weight: 400;
  }

  span {
    color: var(--c--feelgood-mint);
  }
}
