@import '../../../../veneer/src/styles/sass/application';

.container {
  @include property-on-screens('padding', 60px 0, 90px 0, var(--s--spacer-medium) 0);
  background-color: var(--c--smart-blue);
  color: var(--c--brilliant-blue);
}

.grid {
  @extend %grid;
  row-gap: 0;
}

.headline {
  grid-column: 1 / -1;
  @include property-on-screens('margin-bottom', 50px, 83px, 6.6rem);

  @include on-screen(large) {
    display: block;
    grid-column: span 9;
  }
}

.slider {
  grid-column: 1 / -1;
  margin-right: calc(var(--s--wrap) * -1);
}

.table {
  display: grid;
  grid-column: 1 / -1;
  grid-gap: 60px var(--s--grid-gap);
  grid-template-columns: repeat(5, minmax(0, 1fr));

  &__column {
    grid-column: span 1;
  }
}
