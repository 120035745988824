@import '../../../../veneer/src/styles/sass/application';

.container {
  display: flex;
  gap: 10px;
}

.item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 2.1rem;
  height: 1.3rem;
  border: 1px solid var(--c--brilliant-blue);
  border-radius: 4px;

  &__image {
    position: relative !important;
    object-fit: contain;
    max-width: 1.2rem;
    max-height: 0.65rem;
  }
}
