@import '../../../styles/sass/application';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}

.image-container {
  display: block;
  position: relative;

  width: 100%;
  aspect-ratio: 1.21;
  overflow: hidden;
  background-color: var(--c--light-grey);

  .image {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .indicator {
    display: block;
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;
    @include property-on-screens('top', 15px, 18px, 20px);
    @include property-on-screens('right', 15px, 18px, 20px);
    @include property-on-screens('width', 20px, 24px, 27px);
    @include property-on-screens('height', 20px, 24px, 27px);
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(170, 170, 175, 0.25);

    &--active {
      background: #fff center center no-repeat
        url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjE1LjIxMDkiIHkxPSIzLjIxOTA1IiB4Mj0iMi44MjgxIiB5Mj0iMTUuNjAxOSIgc3Ryb2tlPSIjMDAyRDVGIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8bGluZSB4MT0iMi44Mjg0MyIgeTE9IjMuMjI2NTYiIHgyPSIxNS4yOTUiIHkyPSIxNS42OTMxIiBzdHJva2U9IiMwMDJENUYiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=');
    }
  }
}

.title {
  display: block;
  padding-top: 0.5rem;
  @extend %text-short-medium;
}
