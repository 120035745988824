@import '../../../styles/sass/application';

.container-small {
  position: absolute;
  right: 0;
  left: -9px;

  width: 100%;

  .step {
    .title {
      top: unset;
      bottom: 10px;
      color: transparent;
      white-space: nowrap;
    }
    &--active {
      display: flex;
      position: absolute;
      z-index: 10;
      right: 0;
      left: 0;

      justify-content: center;

      .dot {
        display: inline-flex;
      }
      .title {
        transform: translateX(0) !important;
        color: var(--c--smart-blue);
      }
    }
  }

  .dot {
    bottom: -30px;
    display: none;

    &__inner {
      background-color: var(--c--smart-blue);
    }
  }

  .line {
    bottom: -23px;
  }
}

.container {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  margin-bottom: 4rem;
}

.step {
  cursor: pointer;

  &:not(:first-child) {
    .title {
      transform: translateX(-38%);
    }
  }

  &:last-child {
    .title {
      transform: translateX(-90%);
    }
  }

  &--active,
  &:hover {
    .title {
      color: var(--c--smart-blue);
    }

    .dot {
      &__bg {
        background: radial-gradient(50% 50% at 50% 50%, var(--c--smart-blue) 0%, rgba(170, 170, 175, 0) 100%);
      }

      &__inner {
        background-color: var(--c--smart-blue);
      }
    }
  }

  &--current,
  &:hover {
    .dot {
      transform: scale(1.5);
    }
  }
}

.dot {
  display: inline-flex;
  position: absolute;
  bottom: 2px;
  transform-origin: center;
  transition: transform 0.25s ease;

  &__bg {
    display: inline-block;
    flex: 0 0 auto;
    width: 19px;
    height: 19px;
    transition: opacity 0.25s ease;
    background: radial-gradient(50% 50% at 50% 50%, var(--c--middle-grey) 0%, rgba(170, 170, 175, 0) 100%);
    pointer-events: none;

    &--static {
      transform: scale(1) !important;
      opacity: 1 !important;
    }

    &--hide {
      opacity: 0 !important;
    }
  }

  &__inner {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--c--middle-grey);
  }
}

.title {
  position: absolute;
  top: 0;
  color: var(--c--middle-grey);
  white-space: nowrap;
}

.line {
  position: absolute;
  z-index: -1;
  bottom: calc(18px / 2);
  height: 2px;
  transform: translate(calc(18px / 2 * 1), -50%);
  background-color: var(--c--middle-grey);
  pointer-events: none;

  &__fill {
    width: 0;
    height: 100%;
    background: var(--c--smart-blue);
    pointer-events: none;
  }
}
