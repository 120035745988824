@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('../../assets/webfonts/noto-sans-v27-latin-300.woff2') format('woff2'),
    url('../../assets/webfonts/noto-sans-v27-latin-300.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../../assets/webfonts/noto-sans-v27-latin-regular.woff2') format('woff2'),
    url('../../assets/webfonts/noto-sans-v27-latin-regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('../../assets/webfonts/noto-sans-v27-latin-600.woff2') format('woff2'),
    url('../../assets/webfonts/noto-sans-v27-latin-600.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../../assets/webfonts/noto-sans-v27-latin-500.woff2') format('woff2'),
    url('../../assets/webfonts/noto-sans-v27-latin-500.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('../../assets/webfonts/noto-sans-display-v20-latin-300.woff2') format('woff2'),
  url('../../assets/webfonts/noto-sans-display-v20-latin-300.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../../assets/webfonts/noto-sans-display-v20-latin-400.woff2') format('woff2'),
  url('../../assets/webfonts/noto-sans-display-v20-latin-400.woff') format('woff');
}
