@import '../../../../../veneer/src/styles/sass/application';

.container {
  --s--padding-wrap: 1.5rem;

  display: flex;
  flex-direction: column;
  border-radius: var(--s--cart--border-radius);
  background-color: var(--c--light-grey);

  @include on-screen(large) {
    position: sticky;
    top: 1rem;
  }
}

.header {
  padding: 1.8rem var(--s--padding-wrap) 1.25rem;
  border-bottom: var(--border--cart-divider);
}

.title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @extend %heading-4;

  a {
    @extend %textlink-small;
  }
}

.body {
  height: 19rem;
  padding: 0 var(--s--padding-wrap);
}

.footer {
  padding: 1.7rem var(--s--padding-wrap) 2.25rem;

  &__cta {
    display: flex;
    justify-content: stretch;
    gap: 0.75rem;

    &--inactive {
      pointer-events: none;
    }

    button {
      width: 100%;
    }
  }

  &__privacy {
    padding: 1.3rem 0;
    border-top: var(--border--cart-divider);
  }
}

.checkbox + .checkbox {
  margin-top: 15px;
}

.order-reference {
  @extend %subtext;
  padding: 1rem var(--s--padding-wrap) 0.5rem;
  border-bottom: var(--border--cart-divider);
  font-weight: 600;

  &__title {
    margin-bottom: 6px;
    color: rgba(var(--c--intense-blue-rgb), 0.5);
  }

  &__reference {
    @extend %text-short-medium;
    margin-bottom: 9px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
  }
}
