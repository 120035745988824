@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
}

.filter {
  display: flex;
  grid-column: 1/-1;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;

  &__link--inactive {
    pointer-events: none;
  }

  @include on-screen(large) {
    grid-column: 2/-1;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
