@import 'mixins/generate-colors';
@import 'mixins/screen';
@import 'colors';

/**
  Prefixes:
  c  - for color
  s  - for sizes in px, em, rem or %
  fs - for font sizes
 */

:root {
  @include generateColors($colors);

  --s--sidebar-width: 79px;
  --s--spacer-smallest: 60px;
  --fs--body: 20px;
  --s--input-error-margin: 6px;
  --ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);

  --s--cart--border-radius: 4px;
  --s--cart--single-row-height: 98px;
  --s--cart--single-row-horizontal-padding: 55px;
  --s--cart--horizontal-padding: 1.25rem;
  --s--cart--vertical-padding: 1rem;
  --s--cart--mobile-horizontal-padding: 1rem;

  --border--cart-divider: 1px solid rgba(var(--c--middle-grey-rgb), 0.5);

  @include property-on-screens('--fs--interaction', 13px, 14px, 0.75rem);
  @include property-on-screens('--s--wrap', 23px, 37px, 2.75rem);
  @include property-on-screens('--s--ui-element-height', 49px, 49px, 2.95rem);
  @include property-on-screens('--s--header-height', 52px, 63px, var(--s--sidebar-width));
  @include property-on-screens('--s--grid-gap', 13px, 20px, 1.25rem);
  @include property-on-screens('--s--spacer-smaller', 110px, 120px, 5rem);
  @include property-on-screens('--s--spacer-small', 100px, 120px, 6rem);
  @include property-on-screens('--s--spacer-medium', 100px, 120px, 8rem);
  @include property-on-screens('--s--spacer-large', 100px, 120px, 9.5rem);
  @include property-on-screens('--s--input-error-height', 13px, 13px, 0.9rem);
  @include property-on-screens(
    '--s--input-error',
    calc(13px + var(--s--input-error-margin)),
    calc(13px + var(--s--input-error-margin)),
    calc(0.9rem + var(--s--input-error-margin))
  );
}
