@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: var(--s--spacer-small);
  margin-bottom: var(--s--spacer-small);
}

.inner {
  grid-column: 1 / -1;
  padding: 1rem 1.5rem;
  border-radius: 2px;
  background-color: #ff6d75;
}
