@import '../../../styles/sass/application';

.container {
  display: flex;
  align-items: center;
}

.indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  aspect-ratio: 1;

  @include on-screen(medium) {
    width: 16px;
    height: 16px;
  }

  &--available {
    background-color: var(--c--feelgood-mint);
  }

  &--pending,
  &--availableSoon {
    background-color: var(--c--shiny-yellow);
  }

  &--notAvailable {
    background-color: var(--c--middle-grey);
  }
}

.label {
  @extend %subtext-regular;
  margin-left: 7px;

  @include on-screen(medium) {
    margin-left: 12px;
  }
}
