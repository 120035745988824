@import './libs/veneer/src/styles/sass/application';

.container {
  @include property-on-screens('padding-top', 60px, 83px, var(--s--spacer-medium));
  @include property-on-screens('padding-bottom', 60px, 83px, var(--s--spacer-medium));
  background-color: var(--c--cosy-cashmere);
}

.grid {
  @extend %grid;
  row-gap: 0;
}

.slider,
.controls {
  grid-column: 1 / -1;

  @include on-screen(large) {
    grid-column: 2 / span 10;
  }
}

.slider {
  width: 100%;
}

.slide {
  opacity: 1 !important;
}

.controls {
  display: flex;
  justify-content: center;
  @include property-on-screens('margin-top', 28px, 18px, 1.8rem);
  @include property-on-screens('column-gap', 10px, 8px, 0.5rem);
}
