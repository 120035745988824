@mixin vertical-media-item {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-top: percentage(215 / 164);

    @include on-screen(medium) {
      padding-top: percentage(490 / 370);
    }

    @include on-screen(large) {
      padding-top: percentage(850 / 705);
    }
  }

  &__inner {
    position: absolute;
    inset: 0;
  }

  img,
  video {
    object-fit: cover;
    object-position: center;
  }

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
