@import '../../../../../../../../libs/veneer/src/styles/sass/application';

.container {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0 1.3rem;
  border-bottom: 1px solid rgba(var(--c--middle-grey-rgb), 0.5);
}

.information {
  width: 50%;
}

.image {
  flex: 0 0 auto;
  margin-right: 1.25rem;
}

.price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 25%;
}
