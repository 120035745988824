@import 'libs/veneer/src/styles/sass/application';

.container {
  margin-top: var(--s--spacer-smallest);
  margin-bottom: var(--s--spacer-smaller);

  @include on-screen(medium) {
    margin-top: var(--s--spacer-smaller);
  }
}

.headline {
  @include property-on-screens('margin-bottom', 36px, 36, 2.75rem);
  @extend %heading-3;
  text-align: center;
}

.items {
  @extend %grid;
  @include property-on-screens('grid-row-gap', 65px, 65px, 3.25rem);
}

.teaser {
  @include columns-on-screens(span 6, span 2, span 4);
}
