@import './libs/veneer/src/styles/sass/application';

.media {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: percentage(540 / 413);
  }

  &__inner {
    position: absolute;
    inset: 0;
  }

  &__img {
    object-fit: cover;
    object-position: center;
  }
}

.button {
  position: absolute;
  bottom: 20px;
  left: 23px;

  @include on-screen(medium) {
    bottom: 25px;
    left: 25px;
  }

  @include on-screen(large) {
    bottom: 1.5rem;
    left: 1.5rem;
  }
}

.title {
  display: block;
  margin-top: 20px;
  text-align: left;

  @include on-screen(large) {
    margin-top: 1.15rem;
  }
}

.caption {
  margin-top: 2px;

  @include on-screen(large) {
    margin-top: 0.1rem;
  }
}

.extension {
  text-transform: uppercase;
}
