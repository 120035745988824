$icon-width: 18px;

%form-group {
  width: 100%;
}

%form-control {
  width: 100%;
  min-width: auto;
  border: 1px solid transparent;
  @include font-size-on-screens(14px, 15px, 0.75rem);
  @extend %regular;

  .invalid & {
    border-color: var(--c--status-red);
    color: var(--c--status-red) !important;

    &::placeholder {
      opacity: 1;
      color: var(--c--status-red);
    }
  }

  .disabled &,
  .readonly & {
    opacity: 0.5;
    cursor: not-allowed;

    &:focus-visible {
      border-bottom-color: transparent;
    }
  }

  &:focus-visible {
    border-bottom: 1px solid var(--c--smart-blue);
    border-radius: 0;
    outline: none;
  }
}

%input-container {
  position: relative;
}

%input {
  $padding-x: 20px;
  $inner-padding: 10px;

  @extend %form-control;
  height: var(--s--ui-element-height);
  padding: 0 $padding-x;
  color: var(--c--intense-blue);

  &::placeholder {
    opacity: 0.45;
    color: var(--c--intense-blue);
  }

  .icon & {
    padding-left: $padding-x + $icon-width + $inner-padding;
  }

  &--password,
  &--search {
    padding-right: $padding-x + $icon-width + $inner-padding;
  }

  &--search {
    + span {
      position: absolute;
      top: 50%;
      right: 18px;
      width: 25px;
      transform: translateY(-50%);
    }

    &::placeholder {
      opacity: 1;
    }
  }

  &--textarea {
    line-height: 1.2;
    resize: vertical;
  }

  &--white {
    background-color: var(--c--white);
  }

  &--light-grey {
    background-color: var(--c--light-grey);
  }

  &--round {
    border-radius: 100px;

    &:focus-visible {
      border-radius: 100px;
      border-bottom-color: transparent;
    }
  }

  &--square {
    border-radius: 4px;

    &:focus-visible {
      border-radius: 0;
    }
  }
}

%label {
  display: block;
  @include property-on-screens('margin-bottom', 10px, 11px, 0.65rem);
  @include font-size-on-screens(14px, 15px, 0.75rem);
  line-height: 1;
  @extend %bold;

  .required &::after {
    content: '*';
    color: var(--c--feelgood-mint);
  }

  .invalid & {
    &,
    &::after {
      color: var(--c--status-red);
    }
  }

  .disabled &,
  .readonly & {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

%input-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 24px;
  height: auto;
  transform: translateY(-45%);
}

%password-toggle {
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);

  svg {
    display: block;
    width: $icon-width;
    height: auto;
    stroke: var(--c--intense-blue);

    .invalid & {
      stroke: var(--c--status-red);
    }
  }

  .disabled &,
  .readonly & {
    opacity: 0.5;
    pointer-events: none;
  }
}

%error-message {
  position: relative;
  margin-top: var(--s--input-error-margin);
  color: var(--c--status-red);
  @include font-size-on-screens(13px, 13px, 0.65rem);
  line-height: 1;
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    height: var(--s--input-error-height);
  }

  > span {
    position: absolute;
    top: 0;
    left: 0;
  }
}

%checkbox {
  display: block;
  @include font-size-on-screens(15px, 15px, 0.75rem);
  line-height: 1;
  user-select: none;

  form & {
    @extend %regular;
  }

  a {
    @extend %underline;
  }

  &.required::after {
    content: '*';
    color: var(--c--feelgood-mint);
  }

  &.invalid {
    label {
      color: var(--c--status-red);

      &::before {
        box-shadow: 0 0 0 1px var(--c--status-red);
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      padding: 0;
      color: var(--c--intense-blue);
      cursor: pointer;

      form & {
        color: rgba(#002d5f, 0.5);
      }
    }

    & + label::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background: var(--c--white);
      vertical-align: text-top;
    }

    &.light-grey + label::before {
      background: var(--c--light-grey);
    }

    &:focus-visible + label::before {
      box-shadow: 0 0 0 1px var(--c--smart-blue);
    }

    &:disabled + label {
      opacity: 0.5;
      pointer-events: none;
    }

    &:disabled + label::before {
      background: var(--c--light-grey);
      pointer-events: none;
    }

    &:checked + label::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 15px;
      height: 15px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjE1IiB2aWV3Qm94PSIwIDAgMTUgMTUiIHdpZHRoPSIxNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMwMDJkNWYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyLjUiPjxwYXRoIGQ9Im0xMi4wOTc3IDIuNzY3NzctOS4yOTk3NyA5LjI5OTczIi8+PHBhdGggZD0ibTIuNzY3NzcgMi45NTQxIDkuMzYwNjMgOS4zNjA2Ii8+PC9nPjwvc3ZnPg==');
      box-shadow: none;
    }
  }
}

%radio-button {
  display: block;
  @include font-size-on-screens(15px, 15px, 1rem);
  margin-bottom: 26px;
  line-height: 1;
  user-select: none;

  input {
    display: none;

    & + label {
      display: flex;
      position: relative;
      align-items: center;
      padding: 0;
      color: var(--c--intense-blue);
      cursor: pointer;

      form & {
        color: rgba(#002d5f, 0.5);
      }
    }

    & + label::before {
      content: '';
      display: inline-flex;
      flex: 0 0 auto;
      width: 18px;
      height: 18px;
      margin-right: 14px;
      border: 1px solid var(--c--intense-blue);
      border-radius: 50%;
    }

    &:disabled + label,
    &:disabled + label::before {
      opacity: 0.5;
      pointer-events: none;
    }

    &:checked + label::before {
      background: var(--c--intense-blue);
    }

    &:checked + label::after {
      content: '';
      position: absolute;
      left: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--c--white);
    }
  }
}
