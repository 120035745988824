@import '../../../../../../../../libs/veneer/src/styles/sass/application';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 8.25rem;
  padding: 1rem var(--s--cart--mobile-horizontal-padding);
  border-radius: var(--s--cart--border-radius);
  background-color: var(--c--light-grey);
  text-align: center;

  @include on-screen(medium) {
    flex-direction: row;
    text-align: left;
  }

  @include on-screen(large) {
    padding: 1rem var(--s--cart--single-row-horizontal-padding) 1rem 1.2rem;
  }
}

.cta {
  display: flex;
  gap: 1.1rem;
}

.basic-information {
  display: block;

  @include on-screen(medium) {
    display: flex;
    flex: 40% 0 0;
    gap: 1.5rem;
  }

  &__text {
    justify-content: center;
  }
}

.price {
  margin-bottom: 1.75rem;
  text-align: left;

  @include on-screen(medium) {
    margin-bottom: unset;
  }
}

.oldQuantity {
  font-weight: 500;
}
