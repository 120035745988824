@import '../../../styles/sass/application';

.container {
  @extend %text-short;
  display: flex;
  position: relative;
  text-decoration: none;

  --s--icon-size: 19px;
  --s--icon-margin: 10px;

  @include on-screen(large) {
    --s--icon-size: 1.2rem;
    --s--icon-margin: 0.75rem;
  }

  &--brilliant-blue {
    .label {
      color: var(--c--brilliant-blue);
    }

    .decorator {
      background-color: var(--c--brilliant-blue);
    }
  }
}

.decorator {
  content: '\A';
  display: block;
  position: absolute;
  bottom: 0;
  left: calc(var(--s--icon-size) + var(--s--icon-margin));
  width: calc(100% - (var(--s--icon-size) + var(--s--icon-margin)));
  height: 1px;
  transform: scaleX(0);
  background-color: var(--c--intense-blue);
}

.icon {
  flex: 0 0 auto;
  width: var(--s--icon-size);
  height: var(--s--icon-size);
  margin-top: 1px;
  margin-right: var(--s--icon-margin);
  @include property-on-screens('margin-top', 1px, 1px, 0.15rem);
}

.label {
  color: var(--c-intense-blue);
  word-break: break-all;
}
