@import '../../../styles/sass/application';

.inner {
  display: flex;
  flex-direction: column;
  @include property-on-screens('padding', 22px, 45px 54px 50px, 2.5rem 3rem 2rem);
  cursor: pointer;

  &.cosy-cashmere {
    background-color: var(--c--cosy-cashmere);
    color: var(--c--smart-blue);
  }

  &.smart-blue {
    background-color: var(--c--smart-blue);
    color: var(--c--brilliant-blue);
  }

  @include on-screen(large) {
    padding: 2.5rem 3rem 2rem;
  }
}

.title {
  display: block;
  margin-bottom: 2.94117647rem;

  @include on-screen(large) {
    margin-right: 5.5rem;
  }
}

.footer {
  display: flex;
  align-items: flex-end;
  margin-top: auto;
}

.subline {
  display: block;
  flex: 1 1 auto;
}

.icon {
  flex: 0 0 auto;
  width: 2.58823529rem;

  @include on-screen(large) {
    width: 2.7rem;
  }
}
