@import '../../../../libs/veneer/src/styles/sass/application';

.container {
  display: flex;
  position: fixed;
  z-index: 2;
  bottom: 1rem;
  left: 50%;
  align-items: center;
  justify-content: space-between;
  width: 199px;
  height: 71px;
  padding: 0 28px 7px;
  transform: translateX(-50%);
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.menu-toggle {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-right: 6px;

  &__icon {
    width: 40px;
    height: 14px;
  }
}
