@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  @extend %wrap;
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-medium);

  &--ids {
    margin: var(--s--spacer-medium) var(--s--ids-wrap);
    color: var(--c--smart-blue);
  }
}

.top {
  grid-column: 1/-1;
  margin-bottom: 4.4rem;

  @include on-screen(large) {
    grid-column: span 8;
  }
}

.headline {
  margin-bottom: 2.1rem;
  padding-right: 3rem;
}

.slider-container {
  grid-column: 1/-1;
}

.item {
  display: grid !important;
  @extend %grid;
  align-items: center;
  margin: 4rem 0;
  row-gap: 50px;

  @include on-screen(medium) {
    margin: 0;
  }

  &__image {
    position: relative;
    grid-column: 1/-1;
    aspect-ratio: 0.95;

    @include on-screen(medium) {
      grid-column: span 2;
    }

    @include on-screen(large) {
      grid-column: span 5;
    }
  }

  &__content {
    grid-column: 1/-1;

    @include on-screen(medium) {
      grid-column: span 4;
    }

    @include on-screen(large) {
      grid-column: 7 / span 6;
      padding-right: 3rem;
    }
  }

  &__headline {
    margin-bottom: 1.65rem;
  }
}
