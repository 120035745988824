.container {
  cursor: pointer;
  aspect-ratio: 1.12;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
