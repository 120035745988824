.media {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inner {
  position: relative;
  aspect-ratio: 1.5;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.caption {
  margin-top: 0.5rem;
}
