@import '../../../../../../libs/veneer/src/styles/sass/application';

.footer {
  display: none;

  @include on-screen(large) {
    display: flex;
    position: absolute;
    bottom: 2.75rem;
    left: calc(8.33% + var(--s--wrap));
    justify-content: space-between;
    width: 75%;
  }
}
