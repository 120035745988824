.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  width: 5rem;
  height: 5rem;

  &__stop {
    stop-color: var(--c--intense-blue);
  }
}
