@import '../../../styles/sass/application';

.container {
  margin: 0;

  &--vertical {
    display: flex;
    flex-direction: column;
  }

  &--horizontal {
    @extend %grid;

    @include on-screen(large) {
      align-items: center;
    }

    @include below-screen(medium) {
      grid-gap: 21px;
    }
  }
}

.col {
  grid-column: 1/-1;

  &--image {
    position: relative;
  }

  .container--horizontal & {
    @include on-screen(medium) {
      grid-column: span 3;
    }

    &--text {
      @include on-screen(large) {
        grid-column: 2 / span 5;
      }
    }

    &--image {
      @include on-screen(large) {
        grid-column: 8 / span 4;
        order: 1;
      }
    }
  }

  .container--vertical & {
    &--image {
      width: 100%;
      @include property-on-screens('margin-bottom', 21px, 20px, 1.25rem);
      aspect-ratio: 0.75;

      .contact-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.title {
  display: inline;
  margin-right: 13px;

  @include on-screen(medium) {
    margin-right: 18px;
  }
}

.social {
  width: 22px;
  height: 22px;
  margin-bottom: 2px;
  vertical-align: text-bottom;

  @include on-screen(medium) {
    width: 26px;
    height: 26px;
  }
}

.subtitle {
  @extend %subtext;
  @include property-on-screens('margin-top', 4px, 9px, 0.45rem);
  margin-bottom: 25px;

  @include on-screen(medium) {
    @include text-short;
  }
}

.text {
  @extend %text-long;
  @include property-on-screens('margin-bottom', 38px, 28px, 1.5rem);
}

.contact {
  .container--horizontal & {
    @include on-screen(large) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__button {
    display: inline-flex;
    @include property-on-screens('margin-right', 7px, 11px, 1.85rem);
    @include property-on-screens('margin-bottom', 7px, 11px, 0.55rem);
  }
}
