@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin: 0;
  color: var(--c--smart-blue);
}

.intro {
  grid-column: 1 / -1;
  margin-bottom: 3rem;
  @extend %heading-2;

  @include on-screen(large) {
    grid-column: span 10;
    margin-bottom: 9rem;
  }
}

.buttons {
  display: flex;
  grid-column: 1 / -1;
  justify-content: center;
}

.items {
  display: flex;
  grid-column: 1 / -1;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 4rem;
}

.filter-links {
  display: grid;
  grid-column: 1 / -1;
  grid-gap: 33px;
  margin-bottom: 3.5rem;

  @include on-screen(large) {
    grid-gap: 3rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.filter-link {
  display: flex;
  gap: 1.2rem;
  color: var(--c--smart-blue);

  &__icon {
    flex-shrink: 0;
    width: 1.9rem;
    height: 1.9rem;
    margin-top: 0.2rem;
  }

  &__label {
    @extend %textlink-h4;
  }

  &__vacancies {
    display: inline-block;
    margin-top: 0.5rem;
    border-bottom: 1px solid var(--c--smart-blue);
  }

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
