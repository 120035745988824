@import './libs/veneer/src/styles/sass/application';

.container {
  color: var(--c--smart-blue);
  text-align: center;
}

.image {
  display: block;
  width: 112px;
  height: 112px;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;

  @include on-screen(medium) {
    width: 136px;
    height: 136px;
  }

  @include on-screen(large) {
    width: 9rem;
    height: 9rem;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
}

.caption {
  margin-top: 22px;

  @include on-screen(medium) {
    margin-top: 42px;
  }

  @include on-screen(large) {
    margin-top: 3.25rem;
  }
}

.name,
.company {
  display: block;

  @include on-screen(medium) {
    display: inline-block;
  }
}

.company {
  margin-top: 4px;

  @include on-screen(medium) {
    margin-top: 0;
    margin-left: 1em;
  }
}

// animation

.animation {
  .image,
  .quote,
  .caption {
    visibility: hidden;
  }

  &--visible {
    .image,
    .quote,
    .caption {
      visibility: visible;
    }
  }
}
