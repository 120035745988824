.container {
  display: flex;
  max-width: 100%;

  :global .icon {
    input {
      padding-left: 60px;
    }
  }
}

.input {
  position: relative;
  width: 20rem;

  &::after {
    content: '';
    position: absolute;
    top: calc(var(--s--ui-element-height) / 2);
    right: 0;
    width: 1px;
    height: 1.25rem;
    transform: translateY(-50%);
    background-color: var(--c--middle-grey);
  }

  :global {
    .input-icon {
      left: 28px;
    }
  }

  input,
  input:focus-visible {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.dropdown {
  select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
