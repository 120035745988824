.loader {
  &--overlay {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &--inline {
    display: block;
  }

  &__icon {
    width: 5rem;
    height: 5rem;
  }

  &__stop {
    &--black {
      stop-color: var(--c--black);
    }

    &--white {
      stop-color: var(--c--white);
    }

    &--smart-blue {
      stop-color: var(--c--smart-blue);
    }

    &--feelgood-mint {
      stop-color: var(--c--feelgood-mint);
    }

    &--brilliant-blue {
      stop-color: var(--c--brilliant-blue);
    }

    &--shiny-yellow {
      stop-color: var(--c--shiny-yellow);
    }

    &--intense-blue {
      stop-color: var(--c--intense-blue);
    }

    &--cosy-cashmere {
      stop-color: var(--c--cosy-cashmere);
    }

    &--light-grey {
      stop-color: var(--c--light-grey);
    }

    &--middle-grey {
      stop-color: var(--c--middle-grey);
    }

    &--status-red {
      stop-color: var(--c--status-red);
    }
  }
}
