@import '../../../../../../libs/veneer/src/styles/sass/application';

.element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  @include on-screen(medium) {
    padding-top: 17px;
    padding-bottom: 17px;
  }

  @include on-screen(large) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &__label {
    @extend %heading-4;
    color: var(--c--intense-blue);
    text-align: left;
    user-select: none;

    @include on-screen(large) {
      color: var(--c--brilliant-blue);
    }
  }

  &__icon {
    width: 36px;
    height: 36px;
    margin-left: 45px;

    @include on-screen(large) {
      margin-right: 4rem;
      margin-left: 0;
    }
  }
}

.icon {
  &__inner {
    stroke: var(--c--intense-blue);

    @include on-screen(large) {
      stroke: var(--c--brilliant-blue);
    }
  }
}
