@import '../../../styles/sass/application';

.table {
  width: 100%;
  border-spacing: 0 4px;
  border-collapse: separate;

  @include on-screen(medium) {
    width: 100%;
  }

  @include below-screen(medium) {
    width: 100%;
  }

  thead th {
    padding: 0 0.5rem 1rem;
    color: var(--c--middle-grey);
    @extend %text-short-medium;
    text-align: left;

    @include below-screen(medium) {
      display: none;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  tbody {
    td {
      padding: 3.5rem 0.5rem 1.5rem;

      &:first-child {
        padding-left: 1.5rem;
      }
    }

    tr {
      position: relative;
      background: var(--c--light-grey);

      @include on-screen(large) {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          max-height: 0;
          background-color: rgba(#000, 0.05);
          transition: max-height 0.5s ease-in-out;
        }

        &:hover {
          .download-arrow-animate {
            animation: 0.5s upDownArrow var(--ease-in-out-cubic) 0.3s;
          }

          &:after {
            max-height: 100%;
            transition-delay: 0.3s;
          }
        }

        td {
          position: relative;
          z-index: 1;
        }
      }

      @include below-screen(medium) {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        padding: 1rem 0;

        td {
          padding: 0.25rem 0 0.25rem 1.5rem;
        }

        td:not(:last-of-type) {
          margin-bottom: 5px;
        }
      }
    }
  }

  &__title {
    @extend %hyphens;
  }

  &__cta {
    padding-right: 2.4rem !important;
    text-align: right;

    @include below-screen(medium) {
      margin-top: 15px;
      text-align: left;
    }
  }
}
