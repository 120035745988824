@import './libs/veneer/src/styles/sass/application';

.container {
  @include on-screen(large) {
    padding: 1.7rem 0 2.1rem;
  }
}

.divider {
  @include property-on-screens('width', 100%, 55%, 48%);
  height: 1px;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  border: 0;
  background-color: var(--c--middle-grey);
}

.input {
  &--new-password {
    margin-bottom: 0.6rem;
  }
}

.headline {
  margin-bottom: 1.1rem;
}

.buttons {
  margin-top: 1.35rem;
}
