@import '../../../../veneer/src/styles/sass/application';

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--c--light-grey);
  @include property-on-screens('padding', 15px, 15px, 40px);
}

.title {
  @extend %heading-4;
  margin-bottom: 15px;
}

.tagline {
  @extend %subtext;
  font-weight: 400;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3;
  margin: auto 0 30px;
}

.speaker {
  @extend %subtext;
  font-weight: 400;
  margin-bottom: 45px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  gap: 10px;
  border-top: 1px solid var(--c--middle-grey);

  & > * {
    flex-grow: 1;
  }

  button {
    width: 100%;
  }
}
