@import '../../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  grid-row-gap: 0.75rem;
}

.headline {
  @extend %text-short-medium;
}

.headline,
.buttons {
  @include columns-on-screens(span 6, span 6, 3 / span 8);
}

.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}
