@import '../../../styles/sass/application';

.go-back {
  display: flex;
  align-items: center;
  gap: 0.65rem;
  cursor: pointer;

  &__label {
    font-size: 0.75rem;
    @extend %regular;
  }
}
