@import '../../../../../libs/veneer/src/styles/sass/application';

.menu {
  display: flex;
  position: absolute;
  inset: 0;
  flex-direction: column;
  width: 100%;
  background-color: var(--c--white);

  @include on-screen(large) {
    display: block;
    bottom: auto;
    height: 100vh;
    background-color: var(--c--smart-blue);
  }
}

.close {
  position: fixed;
  top: 16px;
  right: var(--s--wrap);

  @include on-screen(medium) {
    top: 24px;
  }

  @include on-screen(large) {
    position: absolute;
    top: 1.8rem;
    right: 3.8rem;
  }
}

.back {
  grid-column: 1 / -1;
  width: 100%;
  margin-bottom: 32px;

  &__button {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__icon {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    background-color: var(--c--light-grey);
  }

  &__svg {
    flex: 0 0 auto;
    width: 18px;
    height: 18px;
  }

  @include on-screen(medium) {
    margin-bottom: 65px;
  }

  @include on-screen(large) {
    display: none;
  }
}

.level-1 {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  padding: 100px var(--s--wrap) 22px;
  overflow-y: auto;

  @include on-screen(medium) {
    padding-top: 136px;
    padding-bottom: 110px;
  }

  @include on-screen(large) {
    padding-top: 8rem;
    padding-right: 0;
    padding-bottom: 12rem;
    overflow: visible;
  }

  &__nav {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-self: flex-start;
    max-width: 100%;

    @include below-screen(large) {
      margin-left: 0 !important;
    }

    @include on-screen(large) {
      width: 33% !important;
      overflow-y: auto;
    }
  }
}

.level-2 {
  $self: &;
  display: flex;
  position: absolute;
  flex-direction: column;
  inset: 0;
  width: 100%;
  padding: 45px calc(var(--s--wrap)) 72px;
  overflow-y: auto;
  background-color: #fff;

  @include on-screen(medium) {
    justify-content: space-between;
  }

  &__nav {
    &--sub {
      width: 100%;
      padding: 10px 0;

      @include on-screen(medium) {
        padding: 2.4rem 0;
        border-width: 1px 0 1px 0;
        border-style: solid;
        border-color: var(--c--middle-grey);
      }
    }
  }

  &__link {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--c--middle-grey);
    cursor: pointer;

    &:last-child {
      border-bottom: 0;
    }

    @include on-screen(medium) {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  @include on-screen(medium) {
    padding-top: 100px;
    padding-bottom: 110px;
  }

  @include on-screen(large) {
    left: auto;
    width: 67% !important;
    padding: 6.25rem calc(var(--s--wrap) + var(--s--sidebar-width)) 4rem 4rem;
  }

  &--cms {
    #{$self}__nav {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 24px;

      @include on-screen(medium) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include on-screen(large) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.2rem;
      }
    }
  }

  &--pim {
    #{$self}__nav {
      &--sub {
        width: 100% !important;
        flex-grow: 1;
      }

      @include on-screen(medium) {
        display: flex;
        flex-wrap: wrap;
        gap: 34px 80px;

        & > * {
          width: calc((100% - 80px * 3) / 4);
        }
      }

      @include on-screen(large) {
        gap: 2.4rem 3rem;

        & > * {
          width: calc((100% - 3rem * 4) / 5);
        }
      }
    }
  }
}

.locale-switch {
  position: absolute;
  top: 24px;
  left: var(--s--wrap);

  @include on-screen(large) {
    display: none;
  }
}

.sidebar {
  flex: 0 0 auto;
  margin-top: 34px;

  @include on-screen(large) {
    display: none;
  }
}

.nav-inner {
  overflow: hidden;
  width: 100% !important;
  display: none;
}
