@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: var(--s--spacer-large);
  margin-bottom: var(--s--spacer-large);
  color: var(--c--smart-blue);

  &--with-image {
    .statement,
    .source {
      grid-column: 1 / -1;
    }

    .statement {
      display: flex;
      align-items: flex-end;
    }

    .image-container {
      grid-column: 1 / -1;
      grid-row: span 2;
    }

    @include on-screen(medium) {
      .statement,
      .source {
        grid-column: span 4;
      }

      .image-container {
        grid-column: span 2;
      }
    }

    @include on-screen(large) {
      .statement,
      .source {
        grid-column: 6 / span 5;
      }

      .image-container {
        grid-column: 3 / span 3;
      }
    }
  }
}

.statement {
  grid-column: 1 / -1;
  @extend %statement;

  @include on-screen(large) {
    grid-column: 2 / span 8;
  }
}

.source {
  display: flex;
  grid-column: 1 / -1;
  gap: 0.8rem;
  margin-top: 1.5rem;

  @include on-screen(large) {
    grid-column: 2 / -1;
    margin-top: 3rem;
  }

  &__primary {
    @extend %subtext-regular;
  }

  &__secondary {
    @extend %subtext;
  }
}
