@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  row-gap: 40px;
  margin-top: var(--s--spacer-smallest);
  margin-bottom: var(--s--spacer-medium);

  > div {
    grid-column: 1/-1;
  }
}

.list-item {
  & + & {
    margin-top: var(--s--spacer-smaller);
  }
}
