@import './libs/veneer/src/styles/sass/application';

.toaster-stack {
  position: fixed;
  z-index: 5;
  top: 1.5rem;
  @include property-on-screens('right', 1rem, 3rem, 5rem);
  @include property-on-screens('width', 341px, 364px, 18.2rem);

  & > * {
    margin-top: 0.5rem;
  }
}
