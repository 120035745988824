@import './libs/veneer/src/styles/sass/application';

.feature {
  display: flex;
  flex-wrap: wrap;
  padding-top: 34px;
  padding-bottom: 34px;
  border-top: 1px var(--c--intense-blue) solid;
  gap: var(--s--grid-gap);

  &:last-child {
    border-bottom: 1px var(--c--intense-blue) solid;
  }

  @include on-screen(medium) {
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 36px;
  }

  @include on-screen(large) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

.title,
.text {
  flex: 1 0 100%;
  line-height: 1.35 !important;
}

.title {
  @include on-screen(medium) {
    flex: 1 1 33%;
  }
}

.text {
  @include on-screen(medium) {
    flex: 1 1 67%;
  }
}
