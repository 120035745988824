@import 'libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;

  &--compact {
    .item-container {
      display: flex;
      gap: 3rem;
      flex-wrap: wrap;
      grid-column: 1 / -1;

      @include below-screen(medium) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &--extended {
    @include property-on-screens('grid-row-gap', 44px, 44px, 5.5rem);
    @include property-on-screens('margin-top', var(--s--spacer-small), var(--s--spacer-smallest), 2.75rem);
    @include property-on-screens('margin-bottom', var(--s--spacer-small), var(--s--spacer-smallest), 2.75rem);

    .item {
      @include columns-on-screens(span 6, span 2, span 4);
    }
  }
}
