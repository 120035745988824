@import '../../../styles/sass/application';

.container {
  display: flex;
  align-items: center;
  height: var(--s--ui-element-height);
  @include property-on-screens('padding', 0 28px, 0 25px, 0 1.5rem);
  border-radius: calc(var(--s--ui-element-height) / 2);

  &--white {
    background-color: var(--c--white);
  }

  &--light-grey {
    background-color: var(--c--light-grey);
  }

  &--faded-white {
    background-color: rgba(#fff, 0.2);

    .input {
      background-color: transparent;
    }
  }
}

.loader {
  position: relative;
  width: 38px;

  &__icon {
    width: 25px;
    transform: translateX(5px);
  }
}

.input {
  width: 38px;
  border: 0;
  outline: 0;
  appearance: none;
  background-color: inherit;
  font-size: var(--fs--interaction);
  text-align: center;

  -moz-appearance: textfield;

  &:focus {
    outline: 0;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.button {
  flex: 0 0 auto;
  @include property-on-screens('width', 12px, 14px, 0.95rem);
  @include property-on-screens('height', 12px, 14px, 0.95rem);
  cursor: pointer;

  &--inactive {
    opacity: 0.35;
    pointer-events: none;
  }
}
