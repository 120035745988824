@import '../../../styles/sass/application';

.inner {
  position: relative;

  .input {
    @extend %subtext-regular;
    box-sizing: border-box;
    width: 100%;
    @include property-on-screens('height', 49px, 47px, 2.1rem);
    @include property-on-screens('padding', 17px 19px 16px 44px, 13px 28px 14px 83px, 0.75rem 1.4rem 0.8rem 3.7rem);
    padding: 17px 19px 16px 44px;
    transition: background-color 0.2s ease;
    border: 0;
    border-radius: 200px;
    background: none;

    &:focus {
      outline: 0;
      background-color: rgba(var(--c--brilliant-blue-rgb), 0.3);
    }

    @include on-screen(medium) {
      font-weight: 300;
    }

    @include on-screen(large) {
      font-weight: 400;
    }
  }

  .icon {
    display: block;
    position: absolute;
    top: 50%;
    @include property-on-screens('left', 15px, 33px, 1.5rem);
    @include property-on-screens('width', 22px, 30px, 1.2rem);
    transform: translateY(-50%);
    pointer-events: none;
  }

  &--light-grey {
    .input {
      background-color: rgba(var(--c--light-grey-rgb), 0.3);
    }

    .icon {
      opacity: 0.3;
    }
  }

  &--brilliant-blue {
    .input {
      background-color: rgba(var(--c--brilliant-blue-rgb), 0.2);

      &,
      &::placeholder {
        color: var(--c--brilliant-blue);
      }
    }
  }

  &--white {
    .input {
      background-color: #fff;

      &,
      &::placeholder {
        color: var(--c--intense-blue);
      }
    }
  }

  &--big .input {
    height: var(--s--ui-element-height);
  }

  &--bordered {
    .input {
      border: 1px solid var(--c--intense-blue);

      &::placeholder {
        opacity: 0.75;
        color: var(--c--intense-blue);
      }
    }
    .icon svg * {
      stroke: var(--c--intense-blue);
    }
  }
}
