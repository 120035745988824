@import './libs/veneer/src/styles/sass/application';

.container {
  display: flex;
  flex-direction: column;
}

.image-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1.037;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.title {
  padding-top: 1.5rem;
  @extend %display;
  font-size: 2rem;
  line-height: 1.25;
}

.date {
  @include property-on-screens('margin-top', 25px, 25px, 2.75rem);
  @include property-on-screens('padding-top', 18px, 25px, 0.9rem);
  border-top: 1px solid var(--c--intense-blue);
  @extend %text-short-medium;
}
