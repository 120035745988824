@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-medium);
}

.headline {
  grid-column: 1/-1;
  margin-bottom: 15px;

  @include on-screen(medium) {
    margin-bottom: 35px;
    text-align: center;
  }

  @include on-screen(large) {
    grid-column: span 3;
    margin-bottom: 0;
    text-align: left;
  }
}

.table {
  display: grid;
  grid-column: 1/-1;
  grid-column-gap: var(--s--grid-gap);
  grid-template-columns: repeat(6, minmax(0, 1fr));

  @include on-screen(large) {
    grid-column: 4 / span 9;
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

.show-all {
  display: inline-flex;
  grid-column: 1/-1;
  align-items: center;
  cursor: pointer;

  &__label {
    margin-left: 20px;
    text-decoration: underline;
  }

  &__icon {
    flex: 0 0 auto;
  }

  @include on-screen(large) {
    grid-column: 4 / span 4;
  }
}

.item {
  grid-column: span 6;

  @include on-screen(medium) {
    grid-column: span 3;
  }
}
