@import '../../../styles/sass/application';

.container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &.inactive {
    opacity: 0.3;
    cursor: not-allowed;
    user-select: none;
  }
}

.image {
  position: relative;
  @include property-on-screens('width', 80px, 90px, 100px);
  @include property-on-screens('height', 80px, 90px, 100px);
  margin-bottom: 15px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  will-change: transform;

  &__inner {
    @include image-cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &--mandrel_ag {
      background-image: url('../../../assets/mandrel/mandrel-ag.jpg');
    }

    &--mandrel_dr {
      background-image: url('../../../assets/mandrel/mandrel-dr.jpg');
    }

    &--mandrel_un {
      background-image: url('../../../assets/mandrel/mandrel-un.jpg');
    }
  }
}
