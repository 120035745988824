@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %page-start;
  padding-bottom: 60px;
  background-color: var(--c--light-grey);

  @include on-screen(large) {
    padding-bottom: var(--s--spacer-medium);
  }
}

.inner {
  @extend %grid;
}

.headline {
  @extend %heading-2;
  grid-column: 1/-1;
  color: var(--c--smart-blue);

  @include on-screen(large) {
    grid-column: span 9;
  }
}

.filter {
  display: flex;
  grid-column: 1/-1;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 14px 10px;
  margin-top: 25px;

  @include on-screen(medium) {
    flex-direction: row;
    align-items: center;
    margin-top: 56px;
  }
}

.dropdown {
  @include property-on-screens('min-width', 0, 317px, 270px);
}

.button {
  align-self: flex-start;
}
