@import '../../../../veneer/src/styles/sass/application';

.list {
  @extend %grid;

  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-top: var(--s--spacer-large);
  margin-bottom: var(--s--spacer-large);
  list-style: none;
  row-gap: 56px;
  counter-reset: item;

  @include on-screen(medium) {
    row-gap: 73px;
  }

  @include on-screen(large) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 67px;
  }
}

.item {
  display: flex;
  counter-increment: item;

  &::before {
    @include heading-4;

    content: counter(item, decimal-leading-zero);
    display: block;
    flex: 0 0 auto;
    width: 40px;
    color: var(--c--brilliant-blue);
    counter-increment: li;

    @include on-screen(medium) {
      width: 60px;
    }

    @include on-screen(large) {
      width: 80px;
    }
  }

  &__inner {
    flex: 1 1 auto;
  }

  &__headline {
    margin-bottom: 21px;
  }
}
