@import '../../../../veneer/src/styles/sass/application';

.inner {
  @extend %grid;
  row-gap: 0;
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-large);
}

.content {
  grid-column: 1 / -1;

  @include on-screen('large') {
    grid-column: 3 / span 8;
  }
}

.headline {
  margin-bottom: 1rem;
}

.media {
  display: grid;
  grid-column: 1 / -1;
  grid-gap: var(--s--grid-gap);
  grid-template-columns: repeat(12, minmax(0, 1fr));
  @include property-on-screens('margin-top', 33px, 62px, 7.5rem);
}

.item {
  @include vertical-media-item;

  grid-column: span 6;

  @include on-screen('large') {
    &:nth-child(odd) {
      grid-column: 2 / span 5;
      margin-bottom: 21.225%;
    }

    &:nth-child(even) {
      grid-column: 8 / span 4;
      margin-top: 57%;
    }
  }
}
