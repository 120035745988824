@import '../../../../libs/veneer/src/styles/sass/application';
.container {
  display: flex;
  position: relative;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.searchbar {
  flex-grow: 1;
}

.autocomplete {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  padding: 1rem 0;

  @include on-screen(large) {
    left: -25%;
    width: 150%;
    padding: 1.35rem 0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.15);
  }
}

.autocomplete-entry {
  display: block;
  width: 100%;
  padding: 0.45rem 1.5rem;
  transition: background-color 0.5s ease;
  text-align: left;

  & > em {
    @extend %text-short-medium;
    font-style: normal;
  }

  @extend %text-short;

  &:hover,
  &:focus {
    outline: 0;
    background-color: var(--c--light-grey);
  }

  @include on-screen(large) {
    padding: 0.25rem 1.5rem;
  }
}
