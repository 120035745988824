@import 'mixins/screen';
@import 'mixins/typo';

%light {
  font-weight: 200;
}

%regular {
  font-weight: 400;
}

%bold {
  @include bold;
}

%display {
  @include display;
}

%display-regular {
  font-family: 'Noto Sans Display', 'Open Sans', sans-serif;
  font-weight: 400;
}

%hyphens {
  @include hyphens;
}

%legend {
  @include legend;
}

%letterspaced {
  @include letterspaced;
}

%intro {
  @include intro;
}

%intro--start {
  @include font-size-on-screens(21px, 30px, 2rem);
  line-height: 1.55;

  @include on-screen(large) {
    text-align: center;
  }
}

%intro--highlight {
  @extend %intro--start;
  font-weight: 400;
  text-transform: uppercase;
}

%text-long {
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.55;
}

%text-short {
  @include text-short;
}

%text-short-light {
  @extend %text-short;
  @extend %light;
}

%text-short-medium {
  @extend %text-short;
  @extend %bold;
}

%subtext {
  @include subtext;
}

%subtext-regular {
  @extend %subtext;
  @extend %regular;
}

%subtext-medium {
  @extend %subtext;
  @extend %bold;
}

%statement {
  @extend %display;
  @include font-size-on-screens(28px, 40px, 2.75rem);
  line-height: 1.25;
}

%tagcloud {
  @include tagcloud;
}

%price {
  @extend %heading-4;
}

%numbers {
  @extend %display-regular;
  @include font-size-on-screens(65px, 75px, 5.75rem);
  line-height: 1.1;
}

%heading-1 {
  @extend %display;
  @extend %hyphens;
  @include font-size-on-screens(50px, 82px, 5.75rem);
  line-height: 1.1;
}

%heading-2 {
  @extend %display;
  @extend %hyphens;
  @include font-size-on-screens(40px, 54px, 3.75rem);
  line-height: 1.1;
}

%heading-3 {
  @include heading-3;
}

%heading-4 {
  @include heading-4;
}

%topline {
  @include topline;
}

%subheading {
  @extend %bold;
  @extend %letterspaced;
  font-size: 1rem;
  line-height: 1.1;
  text-transform: uppercase;
}

%button-text {
  @include button-text;
}

%textlink {
  @include textlink;
}

%textlink-small {
  @include textlink;
  font-size: 0.75rem;
  line-height: 1;
}

%textlink-h4 {
  font-size: 2rem;
  line-height: 1;
}

%crossedout {
  text-decoration: line-through;
}

%underline {
  text-decoration: underline;
}

%unordered-list {
  li {
    padding-left: 1.1em;
    line-height: inherit;
    text-indent: -1.1em;

    p {
      display: inline;
    }

    &::before {
      content: '·';
      margin-right: 0.25em;
      font-family: initial;
      font-size: 2em;
      line-height: 0;
      vertical-align: middle;
    }
  }
}

%commerce {
  font-size: 0.9rem;
}

%commerce-bold {
  @extend %commerce;
  @extend %bold;
}

%ordered-list {
  padding-left: 2em;
  list-style-type: decimal-leading-zero;

  li {
    line-height: inherit;

    p {
      display: inline;
    }
  }
}

%rich-text {
  > * {
    margin-top: 30px;

    @include on-screen(large) {
      margin-top: 1.8rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    text-decoration: underline;
  }

  p {
    & + ol,
    & + ul {
      margin-top: 0;
    }
  }

  ol {
    @extend %ordered-list;
  }

  ul {
    @extend %unordered-list;
  }

  h1,
  h2,
  h3 {
    @extend %bold;
    margin-bottom: 0.15em;
    line-height: 1.1;
  }
}
