@import './libs/veneer/src/styles/sass/application';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrows {
  display: flex;
  gap: 11px;

  a {
    cursor: not-allowed;
  }
}

.dropdown {
  :global(select) {
    @include property-on-screens('min-width', 177px, 169px, 9rem);
  }
}
