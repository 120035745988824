@import '../../../styles/sass/application';

.button {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 0;
  background: none;
  cursor: pointer;
  transition: opacity 0.3s;

  @include property-on-screens('width', 30px, 40px, 52px);

  &:hover {
    opacity: 0.5;
  }
}
