@import './libs/veneer/src/styles/sass/application';

.group {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @include on-screen(medium) {
    font-size: 17px;
  }

  @include on-screen(large) {
    font-size: 0.85rem;
  }

  *::selection {
    background-color: transparent;
  }

  &.disabled,
  &.loading {
    opacity: 0.6;
    pointer-events: none;
  }

  &.invalid {
    color: var(--c--status-red);
  }

  &.locked {
    cursor: not-allowed;
  }

  &.variant--light-grey {
    .toggle {
      background-color: var(--c--light-grey);
    }
  }

  &.variant--middle-grey {
    .toggle {
      background-color: var(--c--middle-grey);
    }
  }
}

.input {
  display: none;

  &:checked + .toggle {
    background-color: var(--c--feelgood-mint);

    &::after {
      transform: translateX(calc(100% + 4px));
    }
  }
}

.toggle {
  display: block;
  flex: 0 0 auto;
  width: 40px;
  height: 20px;
  margin-right: 10px;
  padding: 2px;
  transition: background 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  border-radius: 48px;

  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    transition: transform 0.2s cubic-bezier(0.65, 0, 0.35, 1);
    border-radius: 50%;
    background-color: var(--c--white);
  }
}

.label {
  @extend %regular;
  flex: 1 1 auto;
}
