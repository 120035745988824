@import 'screen';

@mixin display {
  font-family: 'Noto Sans Display', 'Open Sans', sans-serif;
  font-weight: 300;
}

@mixin bold {
  font-weight: 500;
}

@mixin heading-3 {
  @include display;
  @include hyphens;
  @include font-size-on-screens(32px, 40px, 2.75rem);
  line-height: 1.1;
}

@mixin heading-4 {
  @include display;
  @include hyphens;
  @include font-size-on-screens(28px, 34px, 2rem);
  line-height: 1.1;
}

@mixin text-short {
  font-size: 1rem;
  line-height: 1.35;
}

@mixin button-text {
  @include bold;
  font-size: var(--fs--interaction);
  letter-spacing: 0.03em;
  line-height: 1;
  text-transform: uppercase;
}

@mixin subtext {
  @include font-size-on-screens(13px, 14px, 0.75rem);
  line-height: 1.35;
}

@mixin legend {
  @include bold;
  @include font-size-on-screens(17px, 18px, 0.9rem);

  opacity: 0.5;
  line-height: 1.35;
}

@mixin hyphens {
  word-break: normal;
  overflow-wrap: anywhere;
  hyphens: auto;
}

@mixin textlink {
  font-size: 1rem;
  line-height: 1.1;
  text-decoration: underline;
}

@mixin intro {
  @include font-size-on-screens(21px, 24px, 1.4rem);
  line-height: 1.55;
}

@mixin tagcloud {
  @include display;
  @include font-size-on-screens(32px, 54px, 3.75rem);
  line-height: 1.1;
}

@mixin topline {
  @include bold;
  @include letterspaced;
  @include font-size-on-screens(13px, 14px, 0.75rem);
  line-height: 1.1;
  text-transform: uppercase;
}

@mixin letterspaced {
  letter-spacing: 0.01em;
}
