.container {
  position: absolute;
  top: 0;
  right: 0;
  width: 36.1rem;
  max-width: 100%;
  height: 100vh;
  background-color: var(--c--light-grey);
}

.close {
  position: absolute;
  top: 2rem;
  right: 1.25rem;
  transform: scale(1.5);
  transform-origin: right top;
}
