@import './libs/veneer/src/styles/sass/application';

.product-label {
  padding: 0.42em 0.93em;
  border-radius: 15px;
  background: #9be1b4;
  font-size: 14px;

  @media screen and (min-width: 1020px) and (max-width: 1400px) {
    font-size: 10.5px;
  }
}
