@import './libs/veneer/src/styles/sass/application';

.container {
  display: flex;

  &.horizontal {
    .item {
      @include property-on-screens('margin', 0 4px, 0 6px, 0 0.2rem);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.vertical {
    flex-direction: column;

    .item {
      margin: 0.15em 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
