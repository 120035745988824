@import '../../../../libs/veneer/src/styles/sass/application';

.legal {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include on-screen(large) {
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-end;
  }

  &__link {
    @extend %subtext;
    @extend %underline;
    @extend %hyphens;
    @include property-on-screens('margin-top', 11px, 11px, calc(var(--s--grid-gap) / 2));
    @include property-on-screens('margin-left', 0, 0, var(--s--grid-gap));
    color: var(--c--brilliant-blue);
    text-align: left;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    @include on-screen(medium) {
      @include textlink;
    }

    @include on-screen(large) {
      @include subtext;
    }
  }
}
