*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &[lang^='ja'] body,
  &[lang^='zh'] body,
  &[lang='ko'] body,
  &[lang='ko-KR'] body {
    line-break: strict;
    word-break: break-all;
  }
}

body {
  min-height: 100vh;
  margin: 0;
  line-height: 1;
  text-rendering: optimizeSpeed;
  scroll-behavior: smooth;
}

a,
button {
  color: inherit;
  cursor: pointer;
}

button {
  margin: 0;
  padding: 0;
  border-width: 0;
  background-color: transparent;
}

figure {
  margin: 0;
}

input::-moz-focus-inner {
  margin: 0;
  padding: 0;
  border: 0;
}

button,
input,
select,
textarea {
  font: inherit;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

dd,
dl,
menu,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border-width: 0;
}

legend {
  padding-inline: 0;
}

blockquote,
q {
  quotes: none;
  margin: 0;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: '';
  content: none;
}

img,
video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

th {
  font-weight: inherit;
}

td,
th {
  padding: 0;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation-play-state: paused !important;
    scroll-behavior: auto !important;
  }
}
