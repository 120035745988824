@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-top: var(--s--spacer-large);
  margin-bottom: var(--s--spacer-large);

  @include below-screen(medium) {
    row-gap: 65px;
  }

  @include on-screen(medium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include on-screen(large) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  a {
    text-decoration: none;
  }
}
