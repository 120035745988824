@import '../../../../veneer/src/styles/sass/application';

.inner {
  @extend %grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-content: center;

  margin-top: 85px;
  margin-bottom: var(--s--spacer-medium);
  list-style: none;

  @include on-screen(medium) {
    margin-top: var(--s--spacer-medium);
  }

  @include on-screen(large) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  a {
    display: block;
    height: 100%;
    text-decoration: none;
  }
}

.box {
  height: 100%;
}
