@import '../../../../../../libs/veneer/src/styles/sass/application';

.link {
  cursor: pointer;
}

.button {
  $self: &;
  display: block;
  position: relative;
  aspect-ratio: 325 / 218;
  background: var(--c--intense-blue);

  &__image {
    @include image-cover;
  }

  &__static-shade {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.1);
  }

  &__shade {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--c--smart-blue);
  }

  &__label {
    position: absolute;
    z-index: 4;
    bottom: 1.1rem;
    left: 1.4rem;
    transition: color 0.2s ease;
    color: #fff;
    @extend %text-short-medium;
  }

  &:hover {
    #{$self}__label {
      color: var(--c--brilliant-blue);
    }
  }
}
