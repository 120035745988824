@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;

  row-gap: 0;
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-medium);

  &--image {
    .list {
      @include on-screen('medium') {
        grid-column: span 3;
      }

      @include on-screen('large') {
        grid-column: span 5;
      }
    }
  }
}

.headline,
.image,
.list {
  grid-column: span 6;
}

.headline,
.image {
  margin-bottom: 48px;
}

.headline {
  @include on-screen('medium') {
    margin-bottom: 82px;
  }

  @include on-screen('large') {
    grid-column: span 10;
    margin-bottom: 6.8rem;
  }
}

.image {
  @include on-screen('medium') {
    grid-column: span 3;
    order: 2;
    margin-left: calc(var(--s--grid-gap) * 2);
  }

  @include on-screen('large') {
    grid-column: 7 / span 6;
    margin-left: 0;
  }
}

.list {
  @include on-screen('medium') {
    grid-column: span 6;
    margin-right: calc(var(--s--grid-gap) * -1);
  }

  @include on-screen('large') {
    grid-column: span 12;
    margin-right: 0;
  }
}

.item {
  display: block;
  padding-top: 24px;
  padding-bottom: 27px;
  border-top: 1px solid var(--c--intense-blue);

  &:last-child {
    border-bottom: 1px solid var(--c--intense-blue);
  }

  @include on-screen('medium') {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  @include on-screen('large') {
    padding-top: 2.15rem;
    padding-bottom: 2.25rem;
  }
}
