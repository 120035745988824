@import '../../../../veneer/src/styles/sass/application';

.container {
  display: flex;
  margin-top: auto;
  padding: 22px 0 0;
}

.inner {
  display: flex;
  padding: 22px 24px 20px;
  background-color: var(--c--light-grey);

  @include on-screen(large) {
    padding: 32px 38px 35px;
  }
}

.image {
  align-self: flex-start;
  max-width: 68px;
  margin: 0 auto;

  @include on-screen(large) {
    align-self: center;
    max-width: 174px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 40px;

  @include on-screen(medium) {
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
}

.title {
  @include font-size-on-screens(16px, 28px, 2rem);
  margin-bottom: 6px;
  font-weight: 500;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  @include on-screen(medium) {
    margin-bottom: 0;
    font-weight: 300;
    text-transform: initial;
  }
}

.text {
  @include font-size-on-screens(16px, 28px, 2rem);
  @include property-on-screens('line-height', 1.4, 1.2, 1.1);
}
