@import '../../../../libs/veneer/src/styles/sass/application';

.preview {
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.2rem 1.5rem 1.2rem 2rem;
  background-color: rgba(var(--c--feelgood-mint-rgb), 0.95);
  @extend %text-short-medium;
  color: var(--c--intense-blue);
}

.close {
  width: 1.6rem;
  height: 1.6rem;
}

.timestamp {
  @extend %subtext;
  @extend %light;
}
