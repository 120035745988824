.intro {
  @extend %intro;
}

.intro--start {
  @extend %intro--start;
}

.intro--highlight {
  @extend %intro--highlight;
}

.legend {
  @extend %legend;
}

.text-long {
  @extend %text-long;
}

.text-short {
  @extend %text-short;
}

.text-short-medium {
  @extend %text-short-medium;
}

.subtext {
  @extend %subtext;
}

.subtext-medium {
  @extend %subtext-medium;
}

.subtext-regular {
  @extend %subtext-regular;
}

.statement {
  @extend %statement;
}

.tagcloud {
  @extend %tagcloud;
}

.crossedout {
  @extend %crossedout;
}

.textlink {
  @extend %textlink;
}

.textlink-small {
  @extend %textlink-small;
}

.numbers {
  @extend %numbers;
}

.underline {
  @extend %underline;
}

a {
  text-decoration: none;
}
