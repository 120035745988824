@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: var(--s--spacer-small);
  margin-bottom: var(--s--spacer-small);
  overflow: hidden;

  @include between-screen(medium, large) {
    margin-right: 0;
  }
}

.slider {
  grid-column: 1/-1;
  width: 100%;
}

.slide-wrapper {
  @include below-screen(medium) {
    flex-direction: column;
    gap: 74px;
  }
}

.slide {
  @include below-screen(medium) {
    height: auto !important;
  }
}
