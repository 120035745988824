@import '../../../../../veneer/src/styles/sass/application';

.meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  &__fragment {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 3.9rem;
  }

  &__categories {
    display: flex;
    gap: 10px;
    margin-top: 2rem;

    @include on-screen(large) {
      margin-top: 0;
    }
  }

  &__label {
    @extend %text-short-light;
  }

  &__value {
    @extend %text-short-medium;
  }
}

.author {
  display: flex;
  align-items: center;
  gap: 1rem;

  &__image-box {
    position: relative;
    width: 4.9rem;
    height: 4.9rem;

    overflow: hidden;
    border-radius: 50%;
    background-color: var(--c--light-grey);
  }

  &__image {
    @include image-cover;
  }
}
