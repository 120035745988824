.flag {
  display: block;
  width: 23px;
  height: 16px;
  background: no-repeat center;
  background-size: contain;
  line-height: 1;

  &--de {
    background-image: url('../../../assets/flags/de.svg');
  }

  &--us {
    background-image: url('../../../assets/flags/us.svg');
  }

  &--gb {
    background-image: url('../../../assets/flags/gb.svg');
  }

  &--es {
    background-image: url('../../../assets/flags/es.svg');
  }

  &--fr {
    background-image: url('../../../assets/flags/fr.svg');
  }

  &--it {
    background-image: url('../../../assets/flags/it.svg');
  }

  &--br,
  &--pt {
    background-image: url('../../../assets/flags/br.svg');
  }

  &--pl {
    background-image: url('../../../assets/flags/pl.svg');
  }

  &--international {
    background-image: url('../../../assets/flags/international.svg');
  }
}
