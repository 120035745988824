@import '../../../../libs/veneer/src/styles/sass/application';

.address {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: var(--c--brilliant-blue);

  @include on-screen(large) {
    flex-direction: row;
    align-items: flex-end;
  }

  &__item {
    @extend %text-short;
    @include property-on-screens('margin-top', 6px, 6px, calc(var(--s--grid-gap) / 2));
    @include property-on-screens('margin-right', 0, 0, var(--s--grid-gap));
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }

    @include on-screen(large) {
      @include subtext;
    }
  }
}
