@import './libs/veneer/src/styles/sass/application';

.container {
  position: relative;
  @include property-on-screens('padding', 22px, 23px 30px, 1.1rem 1.5rem);
  background-color: var(--c--white);

  @include on-screen(medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  @include on-screen(large) {
    gap: 2rem;
  }
}

.address {
  margin-bottom: 28px;
  line-height: 1.46;

  @include on-screen(medium) {
    width: 45%;
    margin-bottom: 0;
  }

  @include on-screen(large) {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    gap: 0.8rem;
  }

  &__icon {
    display: none;

    @include on-screen(large) {
      display: block;
      flex: 0 0 auto;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__data {
    width: 100%;
    overflow: hidden;
    @include font-size-on-screens(16px, 16px, 0.85rem);
  }

  &__line-wrap {
    @include on-screen(large) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__line {
    display: block;

    @include on-screen(large) {
      display: inline;
    }

    &--country {
      text-transform: uppercase;
    }
  }
}

.toggles {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 33px;

  @include on-screen(medium) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0;
    gap: 15px;
  }

  @include on-screen(large) {
    align-self: flex-end;
    gap: 0.8rem;
    margin-bottom: 0.4rem;
  }
}

.buttons {
  @include on-screen(medium) {
    display: flex;
    gap: 10px;
  }

  @include on-screen(large) {
    gap: 0.6rem;
  }
}

.details {
  &--mobile {
    position: absolute;
    top: 0;
    right: 0;
    padding: 22px 20px;
    transform: rotate(270deg);

    @include on-screen(medium) {
      display: none;
    }
  }
  &--desktop {
    display: none;

    @include on-screen(medium) {
      display: flex;
    }
  }
}
