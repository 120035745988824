@import '../../../../../../libs/veneer/src/styles/sass/application';

.container {
  display: block;
  @extend %grid;

  @include on-screen(large) {
    display: grid;
  }

  &--empty {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.cart-items {
  grid-column: 1 / span 8;
}

.inner {
  grid-column: 9 / span 4;

  &__wrap {
    @include on-screen(large) {
      position: sticky;
      top: 1rem;
    }
  }
}

.order-reference {
  margin-bottom: 1rem;
  padding: 1.2rem 0.85rem 0.4rem 1rem;
  border-radius: var(--s--cart--border-radius);
  background-color: var(--c--light-grey);

  &__title {
    @extend %subtext;
    margin-bottom: 6px;
    font-weight: 600;
  }

  &__content {
    display: flex;
    column-gap: 0.72rem;
  }

  &--added {
    @extend %subtext;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 0 1.15rem;

    &__wrap {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      margin-bottom: 6px;
      color: rgba(var(--c--intense-blue-rgb), 0.5);
      font-weight: 600;
    }

    &__reference {
      @extend %bold;
      font-size: 1rem;
    }

    button {
      @extend %text-short-medium;
      margin-right: 8px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
    }
  }
}

.summary {
  grid-column: 9 / span 4;

  --s--summary--horizontal-padding: 1.75rem;
  align-self: start;
  padding: 1.3rem 0 0;
  border-radius: var(--s--cart--border-radius);
  background-color: var(--c--light-grey);

  &__title {
    padding: 0 var(--s--cart--mobile-horizontal-padding) 1.5rem var(--s--cart--mobile-horizontal-padding);
    @extend %heading-4;

    @include on-screen(large) {
      padding: 0 var(--s--summary--horizontal-padding) 1.5rem;
    }
  }

  &__cta {
    padding: 1.5rem var(--s--cart--mobile-horizontal-padding) 1.4rem var(--s--cart--mobile-horizontal-padding);
    border-top: var(--border--cart-divider);

    @include on-screen(large) {
      padding: 1.5rem var(--s--summary--horizontal-padding) 1.4rem;
    }

    .go-to-checkout {
      width: 100%;
    }
  }

  &__payment-methods {
    padding: 0.75rem var(--s--cart--mobile-horizontal-padding) 1.5rem var(--s--cart--mobile-horizontal-padding);
    @extend %text-short-medium;

    @include on-screen(large) {
      padding: 0.75rem var(--s--summary--horizontal-padding) 1.5rem;
    }
  }
}

.add-voucher,
.cart-item {
  margin-bottom: var(--s--cart--vertical-padding);
}

.active-vouchers {
  display: flex;
  flex-direction: column;
  gap: var(--s--cart--vertical-padding);
  padding-bottom: var(--s--cart--vertical-padding);
}

.empty__label {
  flex-basis: 100%;
  padding-bottom: 4rem;
  @extend %heading-4;
  text-align: center;
}
