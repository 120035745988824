@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  grid-row-gap: 0;
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-large);
}

.headline {
  grid-column: 1 / -1;
  margin-bottom: 35px;

  @include on-screen(medium) {
    margin-bottom: 52px;
    text-align: center;
  }
}

.download-item {
  grid-column: 1 / -1;
  margin-bottom: 2px;
}
