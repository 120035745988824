@import 'libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  grid-row-gap: 2rem;
  margin-top: var(--s--spacer-small);
  margin-bottom: var(--s--spacer-small);
}

.headline {
  grid-column: 1/-1;

  @include on-screen(large) {
    grid-column: span 3;
  }
}

.items {
  @extend %grid;
  grid-column: 1/-1;
  @include property-on-screens('grid-row-gap', 40px, 40px, 6.5rem);
  margin: 0;

  @include on-screen(large) {
    grid-column: span 9;
  }
}

.location {
  grid-column: 1/-1;

  @include on-screen(medium) {
    grid-column: span 3;
  }

  @include on-screen(large) {
    grid-column: span 4;
  }

  &__title {
    margin-bottom: 1.2rem;
    padding-bottom: 1.1rem;
    border-bottom: 1px solid var(--c--intense-blue);
  }

  &__address {
    margin-bottom: 1.75rem;
  }

  &__button {
    display: inline-flex;
  }
}
