@import '../../../../veneer/src/styles/sass/application';

.container {
  position: relative;
  background-color: var(--c--light-grey);
}

.back {
  display: flex;
  position: absolute;
  top: 6.7rem;
  left: var(--s--wrap);
  align-items: center;

  &__icon {
    width: 0.75rem;
    transform: rotate(90deg);

    svg path {
      stroke-width: 2;
    }
  }

  &__label {
    margin-left: 0.3rem;
    font-size: 0.75rem;
    text-transform: uppercase;
  }
}

.table-wrapper {
  @extend %wrap;
  padding-bottom: 8rem;
}

.scroll {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.table {
  width: 100%;
  border-spacing: var(--s--grid-gap) 0;
  border-collapse: separate;
  text-align: left;

  tr:last-child {
    th,
    td {
      padding-top: 2.8rem;
      border-bottom: 0;
      text-align: center;
    }
  }

  th,
  td {
    padding: 1.4rem 0;
    border-bottom: 1px solid var(--c--intense-blue);
    vertical-align: top;
  }

  td {
    @extend %text-short-light;
  }

  th {
    @extend %text-short-medium;

    position: sticky;
    left: 0;

    &,
    &::before {
      background-color: var(--c--light-grey);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      right: calc(var(--s--grid-gap) * -1);
      bottom: -1px;
      width: var(--s--grid-gap);
    }
  }
}

.image {
  margin-bottom: 2.1rem;
}

.price {
  margin-top: 1.35rem;
}
