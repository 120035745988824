@import '../../../../../veneer/src/styles/sass/application';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  @extend %text-short-medium;
  @include property-on-screens('margin-bottom', 20px, 36px, 2.4rem);
  @include property-on-screens('padding-bottom', 20px, 14px, 1rem);
  border-bottom: 1px solid var(--c--brilliant-blue);
}

.item {
  @extend %text-short-medium;
  @include property-on-screens('padding-left', 29px, 40px, 2.15rem);
  @include property-on-screens('text-indent', -29px, -40px, -2.15rem);
  text-decoration: none;

  & + & {
    @include property-on-screens('margin-top', 16px, 18px, 1.15rem);
  }
}

.count {
  @include property-on-screens('width', 19px, 20px, 1.15rem);
  @include property-on-screens('margin-right', 10px, 20px, 1rem);
}
