@import '../../../../veneer/src/styles/sass/application';

.container {
  @include property-on-screens(
    'margin',
    65px 0 var(--s--spacer-medium),
    var(--s--spacer-smallest) 0 var(--s--spacer-medium),
    0
  );

  @include on-screen(large) {
    padding: 0 0 var(--s--spacer-medium);
    background-color: var(--c--light-grey);
  }
}

.grid {
  @extend %grid;
}

.headline {
  grid-column: 1/-1;
  margin-bottom: 5px;
  padding-right: 10%;

  @include on-screen(medium) {
    grid-column: span 2;
    margin-bottom: 0;
  }

  @include on-screen(large) {
    grid-column: span 3;
  }
}

.text {
  grid-column: 1/-1;
  margin-bottom: 13px;

  @include on-screen(medium) {
    grid-column: span 4;
    margin-bottom: 33px;
  }

  @include on-screen(large) {
    grid-column: span 9;
    margin-bottom: 2.25rem;
  }
}

.accordions {
  grid-column: 1/-1;

  @include on-screen(large) {
    grid-column: 4/-1;
  }
}
