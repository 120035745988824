@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  grid-row-gap: 50px;

  &--h1 {
    @extend %page-start;
    margin-bottom: var(--s--spacer-small);

    .headline,
    .intro {
      grid-column: 1/-1;

      @include on-screen(large) {
        grid-column: span 8;
      }
    }

    .headline {
      @extend %heading-2;
    }
  }

  &--h1-indented {
    @extend %page-start;
    margin-bottom: var(--s--spacer-small);

    .intro {
      grid-column: 1 / -1;

      @include on-screen(large) {
        grid-column: 4 / span 8;
        margin-top: 4rem;
      }
    }

    .headline {
      grid-column: 1 / span 5;
      @extend %heading-1;
      color: var(--c--smart-blue);

      @include on-screen(large) {
        grid-column: 2 / span 9;
      }
    }
  }

  &--section-headline {
    margin-top: var(--s--spacer-large);
    margin-bottom: var(--s--spacer-medium);
    text-align: center;

    .intro {
      grid-column: -1 / 1;
      @include on-screen('large') {
        grid-column: 3 / span 8;
      }
    }

    .headline {
      grid-column: -1 / 1;
      @extend %heading-1;
      color: var(--c--smart-blue);

      @include on-screen('large') {
        grid-column: 2 / span 10;
      }
    }
  }

  &--grey {
    background-color: var(--c--light-grey);
  }
}

.intro {
  @extend %rich-text;
  @extend %intro;
}
