@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  @include property-on-screens('grid-row-gap', 38px, 58px, 2.5rem);
  margin-top: var(--s--spacer-small);
  margin-bottom: var(--s--spacer-small);

  &--in-article {
    margin-top: 2rem;
  }

  &:not(.container--in-article) {
    .headline {
      @include on-screen(medium) {
        text-align: center;
      }
    }
  }
}

.headline {
  grid-column: 1 / -1;
  @extend %heading-3;
}

.teaser {
  @include columns-on-screens(span 6, span 3, span 4);
}
