@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin: 0;
  row-gap: 42px;
}

.left {
  display: flex;
  grid-column: 1 / -1;
  flex-direction: column;

  @include on-screen(medium) {
    display: flex;
    flex-direction: row;
  }

  @include on-screen(large) {
    display: block;
    grid-column: span 4;
    grid-row: 1;
  }
}

.right {
  grid-column: 1 / -1;

  @include on-screen(large) {
    grid-column: span 8;
    grid-row: 1;
  }
}

.text-container {
  order: 0;

  @include on-screen(medium) {
    order: 1;
  }
}

.image {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  order: 1;
  aspect-ratio: 0.74;
  background-color: var(--c--light-grey);

  @include on-screen(medium) {
    order: 0;
    width: 260px;
    margin-right: 30px;
  }

  @include on-screen(large) {
    width: auto;
    margin-right: 3rem;
  }

  img {
    padding: 0 60px 0 40px;

    @include on-screen(medium) {
      padding: 0 47px 0 30px;
    }

    @include on-screen(large) {
      padding: 0;
    }
  }
}

.title {
  @include property-on-screens('margin-bottom', 28px, 27px, 0.8rem);
}

.description {
  margin-bottom: 44px;

  @include on-screen(large) {
    width: 87%;
    margin-bottom: 2.6rem;
  }
}

.variants {
  @include on-screen(medium) {
    margin-bottom: 40px;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  &__link {
    margin-left: 6px;
  }
}
