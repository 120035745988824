@import '../../../styles/sass/application';

.container {
  &--style-expanded {
    label {
      @include font-size-on-screens(14px, 14px, 0.9rem);
      @extend %bold;
      margin-bottom: 7px;
    }

    .description {
      @extend %commerce;
      margin-left: 33px;
      line-height: 1;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--style-boxed {
    display: grid;
    gap: 1rem;
    --grid-columns: 4;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    width: 100%;

    @media screen and (max-width: 1280px) {
      --grid-columns: 3;
    }
    @media screen and (max-width: 720px) {
      --grid-columns: 2;
    }
    @media screen and (max-width: 480px) {
      --grid-columns: 1;
    }

    label {
      @include font-size-on-screens(14px, 14px, 0.9rem);
      @extend %bold;
      white-space: nowrap;
    }

    .radio-button {
      width: 100%;
      margin-bottom: 0;
      padding: 1.4rem;
      border-radius: 4px;
      background: var(--c--white);

      @include on-screen(medium) {
        max-width: 315px;
      }

      &.checked {
        box-shadow: 0 0 0 1px rgba(var(--c--intense-blue-rgb), 0.4);
      }

      &.unchecked.dirty {
        background: rgba(var(--c--white-rgb), 0.5);
      }

      &.unchecked.dirty label,
      &.unchecked.dirty input {
        opacity: 0.5;
      }
    }
  }

  &--style-basic {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;

    .radio-button {
      margin-bottom: 0;
      font-size: 1rem;
    }
  }
}
