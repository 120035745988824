@import '../../../../../veneer/src/styles/sass/application';

.container {
  display: flex;
  flex-wrap: wrap;
  @include property-on-screens('padding', 20px 15px, 17px 23px 17px 18px, 1.25rem 1.5rem);
  background-color: var(--c--light-grey);
  align-items: center;

  > a {
    display: contents;
  }
}

.container .image {
  position: relative;
  flex: 0 0 auto;
  @include property-on-screens('width', 70px, 94px, 4.35rem);
  aspect-ratio: 1;
  @include property-on-screens('margin-right', 19px, 19px, 0.75rem);
  object-fit: contain;
  align-self: start;
}

.title {
  @include property-on-screens('margin-bottom', 2px, 3px, 0.25rem);
}

.subline {
  @include property-on-screens('margin-bottom', 0, 14px, 0.85rem);
  @extend %hyphens;
}

.inner {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;

  @include on-screen(medium) {
    gap: 1rem;
  }
}

.infos {
  flex: 1;
}

.commerce {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 22px;
  padding-top: 26px;
  border-top: 1px solid var(--c--middle-grey);

  @include on-screen(medium) {
    width: auto;
    margin-top: 0;
    padding-top: 0;
    border: 0;
  }
}

.cart {
  display: flex;
  margin-left: auto;
  padding-left: 1rem;

  > div:last-of-type {
    display: contents;
  }

  @include below-screen(medium) {
    margin-right: auto;
    margin-left: auto;
    margin-top: 1.1rem;
    padding-top: 1.8rem;
    padding-left: 0;
    width: 100%;
    border-top: 1px solid #aaaaaf;
    align-items: center;

    > div:last-of-type {
      margin-left: auto;
      margin-right: 0;
      display: flex;
    }
  }

  &__button {
    margin-left: 12px;
  }
}
