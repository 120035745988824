@import './libs/veneer/src/styles/sass/application';

.grid {
  @extend %grid;
  row-gap: 23px;
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-medium);

  @include on-screen(medium) {
    row-gap: 38px;
  }

  @include on-screen(large) {
    row-gap: 2.25rem;
    margin-right: var(--s--sidebar-width);
  }
}

.headline,
.text,
.slider,
.misc {
  grid-column: -1 / 1;
}

.headline {
  @include on-screen('medium') {
    grid-column: span 2;
  }

  @include on-screen('large') {
    grid-column: span 3;
  }
}

.text {
  width: 100%;
  margin-bottom: 10px;

  @include on-screen('medium') {
    margin-bottom: 0;
  }

  @include on-screen('large') {
    width: 73%;
  }
}

.intro {
  display: flex;
  grid-column: 1/-1;
  justify-content: space-between;
  padding-right: var(--s--wrap);

  @include on-screen('medium') {
    grid-column: span 4;
  }

  @include on-screen('large') {
    grid-column: span 9;
  }
}

.misc {
  @include property-on-screens('margin-bottom', 16px, 24px, 0);

  @include on-screen('medium') {
    grid-column: 3 / span 2;
  }

  @include on-screen('large') {
    grid-column: span 3;
  }
}

.slider {
  margin-right: calc(var(--s--wrap) * -1);

  @include on-screen('medium') {
    grid-column: 2 / span 5;
  }

  @include on-screen('large') {
    grid-column: span 9;
    margin-right: 0;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: calc(var(--s--grid-gap) / 2);
}

.controls {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.5rem;
}
