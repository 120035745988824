@import '../../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: 2rem;
  margin-bottom: 2.9rem;
}

.inner {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  align-items: stretch;
  @include columns-on-screens(span 6, span 6, 3 / span 8);
}

.image__container {
  display: none;
  position: relative;
  grid-column: span 2;

  @include on-screen(medium) {
    display: block;
  }

  @include on-screen(large) {
    aspect-ratio: 1.278;
  }
}

.image {
  @include image-cover;
}

.text__container {
  grid-column: span 8;
  padding: 1.2rem 1.4rem;
  background-color: var(--c--light-grey);

  @include on-screen(medium) {
    grid-column: span 6;
  }

  .headline {
    @extend %heading-4;
    margin-bottom: 0.85rem;
  }

  .contact__name {
    @extend %text-short-medium;
  }

  .contact__title {
    @extend %text-short-light;
  }

  .contact__email {
    display: inline-flex;
    margin-top: 0.5rem;
  }
}
