@import '../../../../../veneer/src/styles/sass/application';

.container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 100px var(--s--wrap);
  color: var(--c--brilliant-blue);
  text-align: center;

  @include on-screen(medium) {
    padding-right: 40px;
    padding-left: 40px;
  }

  &.variant--small {
    @include on-screen(large) {
      min-height: 37.05rem;
    }
  }

  &.variant--large {
    min-height: 850px;
  }
}

.headline,
.text {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);

  @include on-screen(medium) {
    margin-right: auto;
    margin-left: auto;
  }
}

.headline {
  @include property-on-screens('margin-bottom', 39px, 39px, 60px);

  .variant--large & {
    @include on-screen(large) {
      max-width: 57.15rem;
    }
  }

  .variant--small & {
    @include on-screen(large) {
      margin-bottom: 1.1rem;
    }
  }
}

.text {
  @include property-on-screens('margin-bottom', 45px, 37px, 37px);

  @include on-screen(medium) {
    max-width: 79%;
  }

  .variant--large & {
    @include on-screen(large) {
      max-width: 42.3rem;
    }
  }

  .variant--small & {
    @include on-screen(large) {
      margin-bottom: 1.05rem;
    }
  }
}

.media {
  position: absolute;
  inset: 0;
  z-index: -1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    z-index: 1;
    opacity: 0.45;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0a192d;
    mix-blend-mode: darken;
  }

  &__element {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
