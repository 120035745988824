@import '../../../../veneer/src/styles/sass/application';

.button {
  display: block;
  width: 100%;
  border: 0;
  background: none;
  cursor: pointer;
}

.image {
  width: 100%;
  padding-top: percentage(9 / 16);
  img {
    object-fit: cover;
  }
}

.image-container {
  position: relative;
  overflow: hidden;
}

.icon {
  position: absolute;
  bottom: 1.25rem;
  left: 1.4375rem;

  @include on-screen('medium') {
    bottom: 1.29rem;
    left: 1.53rem;
  }

  @include on-screen('large') {
    bottom: 1.6rem;
    left: 1.5rem;
  }
}

.title {
  display: block;
  margin-top: 0.75rem;
  text-align: left;
}
