$screensizes: (
  'small': 0,
  'medium': 760px,
  'large': 1020px,
);

@mixin on-screen($name) {
  @if map-has-key($screensizes, $name) {
    @media (min-width: #{map-get($screensizes, $name)}) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$screensizes}`. '
        + 'Please make sure it is defined in `$screensizes` map.';
  }
}

@mixin below-screen($name) {
  @if map-has-key($screensizes, $name) {
    @media (max-width: #{map-get($screensizes, $name) - 1px}) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$screensizes}`. '
        + 'Please make sure it is defined in `$screensizes` map.';
  }
}

@mixin above-screen($name) {
  @if map-has-key($screensizes, $name) {
    @media (min-width: #{map-get($screensizes, $name)}) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$screensizes}`. '
        + 'Please make sure it is defined in `$screensizes` map.';
  }
}

@mixin between-screen($nameMin, $nameMax) {
  @if (map-has-key($screensizes, $nameMin) && map-has-key($screensizes, $nameMax)) {
    @media (min-width: #{map-get($screensizes, $nameMin)}) and (max-width: #{map-get($screensizes, $nameMax) - 1px}) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$screensizes}`. '
        + 'Please make sure it is defined in `$screensizes` map.';
  }
}

@mixin property-on-screens($property, $small, $medium, $large) {
  #{$property}: $small;

  @include on-screen(medium) {
    #{$property}: $medium;
  }

  @include on-screen(large) {
    #{$property}: $large;
  }
}

@mixin font-size-on-screens($small, $medium, $large) {
  @include property-on-screens('font-size', $small, $medium, $large);
}

@mixin columns-on-screens($small, $medium, $large) {
  @include property-on-screens('grid-column', $small, $medium, $large);
}
