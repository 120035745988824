@import '../../../../veneer/src/styles/sass/application';

.container {
  padding: 0 var(--s--cart--mobile-horizontal-padding);
  border-radius: var(--s--cart--border-radius);
  background-color: var(--c--light-grey);

  @include on-screen(large) {
    padding: 0 var(--s--cart--single-row-horizontal-padding);
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--s--cart--single-row-height);
  @extend %commerce-bold;
  text-transform: uppercase;
  cursor: pointer;
}

.form {
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 1.15rem;
    padding-bottom: 1.5rem;

    @include on-screen(medium) {
      flex-direction: row;
    }
  }

  &__input {
    width: 100%;

    @include on-screen(medium) {
      width: 40%;
    }
  }
}
