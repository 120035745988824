@import './libs/veneer/src/styles/sass/application';

.top {
  position: relative;
  overflow: hidden;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 0.76;
    background-color: var(--c--light-grey);
  }
}

.image {
  padding: 0 20px;

  img {
    object-fit: contain;
  }
}

.title {
  @include property-on-screens('margin-bottom', 5px, 3px, 0.1rem);
}

.tag {
  @include property-on-screens('margin-top', 15px, 21px, 0.85rem);
  margin-right: 10px;

  &:first-child {
    margin-right: 0;
  }
}

.delivery-status {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  @include property-on-screens('height', 61px, 67px, 3.75rem);
  @include property-on-screens('padding', 0 30px, 0 27px, 0 1.5rem);
  background-color: rgba(#fff, 0.6);

  &--static {
    @extend %subtext-regular;
  }
}

.product-labels {
  display: flex;
  position: absolute;
  top: 22px;
  left: 0;
  flex-wrap: wrap;
  margin-inline: 27px;
  color: #002d5f;
  font-weight: 100;
  line-height: 0.95em;
  pointer-events: none;
  gap: 0.5rem;
}

[class*='delivery-status'] ~ .product-labels {
  @include property-on-screens('top', 77px, 83px, 4.75rem);
}

.variants {
  position: absolute;
  bottom: 27px;
  left: 32px;
  pointer-events: none;
}

.cart-button {
  position: absolute;
  @include property-on-screens('bottom', 23px, 25px, 1.5rem);
  @include property-on-screens('left', 13px, 25px, 1.5rem);
}

.bottom {
  display: flex;
  align-items: flex-start;
  @include property-on-screens('margin-top', 12px, 21px, 1.15rem);

  &__inner {
    flex: 1 1 auto;
  }
}

.wishlist {
  flex: 0 0 auto;
}
