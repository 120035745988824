@import '../../../../../../veneer/src/styles/sass/application';

.container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @include property-on-screens('padding', 28px 22px 22px, 37px 25px 25px, 2.1rem 1.5rem 1.5rem);
  background-color: var(--c--brilliant-blue);
}

.title {
  @extend %heading-3;
  margin-bottom: 19px;

  @include on-screen(medium) {
    @include heading-4;
    margin-bottom: 24px;
  }
}

.info-container {
  display: flex;
  align-items: center;
  @include property-on-screens('margin-bottom', 35px, 58px, 3.5rem);

  > div + div {
    @include property-on-screens('margin-left', 21px, 41px, 2.6rem);
  }
}

.sale-container {
  display: flex;
  margin-top: 1.5rem;

  div + div {
    margin-left: 5px;

    @include on-screen(medium) {
      margin-left: 10px;
    }
  }
}

.price-container {
  @include property-on-screens('margin-bottom', 22px, 25px, 1.45rem);
}

.close-button {
  align-self: flex-start;
  margin-top: auto;
}
