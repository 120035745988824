@import './libs/veneer/src/styles/sass/application';

.information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    @extend %letterspaced;
    @extend %commerce-bold;
    margin-bottom: 0.2rem;
    line-height: 1.1;
    text-transform: uppercase;
  }

  &__description,
  &__sku {
    margin-bottom: 0.6rem;
    @extend %commerce;
    line-height: 1.35;
  }

  &__quantity {
    @extend %bold;
    font-size: 0.75rem;
    line-height: 1.35;
  }

  &__delivery {
    min-height: 0.75rem;
    margin: 1.3rem 0 0;
  }
}
