$colors: (
  'black': #000,
  'white': #fff,
  'smart-blue': #005ba0,
  'feelgood-mint': #9be1b4,
  'brilliant-blue': #78dcff,
  'shiny-yellow': #fff055,
  'intense-blue': #002d5f,
  'cosy-cashmere': #f2e9dd,
  'light-grey': #eeeef3,
  'middle-grey': #aaaaaf,
  'status-red': #de3c3c,
);
