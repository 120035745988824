@import './libs/veneer/src/styles/sass/application';

.container,
.test {
  width: 100%;
}

.test {
  visibility: hidden;
  height: 0;
  overflow-x: scroll;
  white-space: nowrap;
}
