@import '../../../../veneer/src/styles/sass/application';

.container {
  margin-top: 2rem;
}

.slide {
  position: relative;
  aspect-ratio: 2.134;
  background-color: #fff;

  @include below-screen(medium) {
    aspect-ratio: 0.99;
  }

  &__image {
    @include image-cover;
    z-index: 0;
  }

  &__gradient {
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    @include property-on-screens('height', 65%, 55%, 55%);
    background: linear-gradient(180deg, rgba(10, 25, 45, 0) 5.33%, #0a192d 98.18%);
  }

  &__content {
    display: block;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
  }
}

.content {
  display: block;
  width: 100%;
  padding: 0 3.6rem 3.6rem;
  @include property-on-screens('padding', 0 20px 20px, 0 35px 35px, 0 3.6rem 3.6rem);
  color: #fff;

  &__date {
    display: block;
    @extend %text-short-light;
  }

  &__title {
    display: block;
    width: 75%;
    @include property-on-screens('width', 100%, 85%, 75%);
    margin-top: 0.25rem;
    font-size: 2rem;
  }

  &__excerpt {
    @include property-on-screens('display', none, block, block);
    @include property-on-screens('width', 100%, 85%, 75%);
    margin-top: 0.6rem;
    @extend %text-long;
  }
  &__button {
    display: block;
    margin-top: 1.9rem;
  }
}

.swiper-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 2rem 0 0;
}

.swiper-indicator {
  padding: 1rem 0;

  &__bar {
    position: relative;
    width: 3.45rem;
    height: 3px;
    background-color: var(--c--middle-grey);
  }

  &:hover .swiper-indicator__inner {
    width: 100%;
    background-color: var(--c--feelgood-mint);
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--c--intense-blue);

    &--active {
      width: 100%;
    }
  }
}
