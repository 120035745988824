@import './libs/veneer/src/styles/sass/application';

.more {
  @include property-on-screens('margin-top', 17px, 17px, 0.85rem);
}

.pill-container {
  display: grid;
  grid-gap: 11px 10px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.item--pill {
  grid-column: span 3;
}

.pill {
  width: 100%;
  text-align: center;
}

.item--checkbox {
  & + & {
    @include property-on-screens('margin-top', 12px, 12px, 0.6rem);
  }
}
