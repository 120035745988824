@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.headline {
  grid-column: 1 / -1;
  @extend %subheading;
}

.subline {
  grid-column: 1 / -1;
  margin-bottom: 2rem;
  @extend %text-long;
}

.bundle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.5rem 2rem 1.25rem;
  @include columns-on-screens(span 6, span 3, span 4);
  background: var(--c--smart-blue);

  color: var(--c--brilliant-blue);

  &__title {
    margin-bottom: 1.3rem;
    padding-bottom: 1.8rem;
    border-bottom: 1px solid var(--c--brilliant-blue);
    @extend %heading-3;
  }

  &__price {
    justify-self: flex-end;
  }
}

.modules {
  &__title {
    margin-bottom: 0.55rem;
    @extend %subheading;
  }

  &__amount {
    color: var(--c--feelgood-mint);
  }

  &__list {
    list-style-position: inside;
    list-style-type: disc;
    line-height: 1.35;
  }

  &__item {
    @extend %text-short-light;
  }

  &__more {
    padding: 4px 0 0 1.3rem;
    @extend %text-short-medium;
    color: var(--c--feelgood-mint);
  }
}

.includes {
  margin-top: 2.5rem;
  &__item {
    display: flex;
    align-items: center;
    padding: 0.7rem 0;
    @extend %subheading;
  }

  &__icon {
    width: 0.85rem;
    margin-right: 0.5rem;

    path {
      stroke-width: 3;
    }
  }
}

.prices {
  margin-top: 1.8rem;

  &__prefix {
    @extend %subtext-regular;
  }

  &__price {
    @extend %price;
  }

  &__suffix {
    margin-top: 3px;
    @extend %subtext;
  }
}
