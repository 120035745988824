@import '../../../styles/sass/application';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include property-on-screens('width', 100%, 91vw, 52.5vw);
  @include property-on-screens('min-height', 0, 0, 26.1rem);
  @include property-on-screens('padding', 40px 26px, 55px 45px, 2.5rem 4rem);
  background-color: #fff;
  text-align: center;

  @include on-screen(medium) {
    border-radius: 4px;
  }
}

.icon {
  margin-right: auto;
  margin-bottom: 1.65rem;
  margin-left: auto;
}

.headline {
  margin-bottom: 1.2rem;
}

.text {
  margin-bottom: 1.85rem;
}
