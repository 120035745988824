@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;

  margin-top: var(--s--spacer-small);
  margin-bottom: var(--s--spacer-small);
}

.column {
  grid-column: 1 / -1;

  &--left {
    @include on-screen(large) {
      grid-column: span 3;
    }
  }

  &--right {
    margin-bottom: 36px;

    @include on-screen(large) {
      grid-column: span 9;
      margin-bottom: 0;
    }
  }
}

.copy {
  @extend %rich-text;
}

.accordion {
  margin-top: var(--s--spacer-large);
}
