@import '../../../../veneer/src/styles/sass/application';

.cta-area {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.quantifier {
  @include on-screen(large) {
    margin-right: 30px;
  }
}
