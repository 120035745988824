@import '../../../styles/sass/application';

.container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.discount-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: var(--c--feelgood-mint);
  aspect-ratio: 1
;
  &__icon {
    width: 7px;
    margin-top: -8px;
    stroke-width: 4;
  }
}

.discount-label {
  @extend %commerce-bold;
}
