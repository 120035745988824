@import '../../../../../veneer/src/styles/sass/application';

.container {
  --s--padding-wrap: 2.1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.header {
  padding: 1.8rem var(--s--padding-wrap) 3rem;
}

.title {
  @extend %heading-4;
}

.body {
  flex-grow: 1;
  padding: 0 var(--s--padding-wrap);

  &--empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.empty-cart {
  text-align: center;

  &__title {
    margin-bottom: 1rem;
    @extend %text-short-medium;
  }

  &__description {
    width: 60%;
    @extend %text-short-light;
  }

  &__links {
    display: flex;
    gap: 1rem;
    padding-top: 3rem;
  }
}

.footer {
  padding: 1.7rem var(--s--padding-wrap) 3.75rem;

  &__cta {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    padding: 2rem 0 0;

    &--inactive {
      pointer-events: none;
    }
  }
}
