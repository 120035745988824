@import '../../../../../../veneer/src/styles/sass/application';

.cart-cta {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    width: auto;
    @include property-on-screens('height', 2rem, 4rem, 8rem);
  }

  &__label {
    padding-top: 0.75rem;
    @extend %commerce;
  }
}
