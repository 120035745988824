@import '../../../styles/sass/application';

.inner {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--s--ui-element-height);
  height: var(--s--ui-element-height);
  border-radius: 50%;
  cursor: pointer;

  &.fill-background {
    transition: background-color 0.5s ease;
    &.pseudo-hover,
    &:hover {
      background-color: var(--c--intense-blue);
    }
  }

  & > .icon {
    @include property-on-screens('width', 20px, 22px, 1.3rem);
    @include property-on-screens('height', 20px, 22px, 1.3rem);
  }

  &.shiny-yellow {
    background: var(--c--shiny-yellow);
  }

  &.brilliant-blue {
    background: var(--c--brilliant-blue);
  }

  &.feelgood-mint {
    background: var(--c--feelgood-mint);
  }

  &.light-grey {
    background: var(--c--light-grey);
  }

  &.white {
    background: var(--c--white);
  }

  &.outline {
    border: 1px solid var(--c--intense-blue);
    background: transparent;
  }

  &.outline-color {
    &--shiny-yellow {
      border-color: var(--c--shiny-yellow);
    }

    &--brilliant-blue {
      border-color: var(--c--brilliant-blue);
    }

    &--feelgood-mint {
      border-color: var(--c--feelgood-mint);
    }

    &--smart-blue {
      border-color: var(--c--smart-blue);
    }

    &--white {
      border-color: var(--c--white);
    }
  }

  &.medium {
    @include property-on-screens('width', 49px, 50px, 4.1rem);
    @include property-on-screens('height', 49px, 50px, 4.1rem);

    .icon {
      @include property-on-screens('width', 17px, 18px, 1.3rem);
      @include property-on-screens('height', 17px, 18px, 1.3rem);
    }
  }

  &.large {
    @include property-on-screens('width', 90px, 72px, 7rem);
    @include property-on-screens('height', 90px, 72px, 7rem);

    .icon {
      @include property-on-screens('width', 25px, 18px, 1.4rem);
      @include property-on-screens('height', 25px, 18px, 1.4rem);
    }
  }

  &.inactive {
    opacity: 0.25;
    cursor: default;
  }

  &.not-interactive {
    cursor: default;
  }
}

.label {
  @extend %topline;
  padding: 0 30px;
  color: var(--c--smart-blue);
  text-align: center;

  @include on-screen(medium) {
    @include button-text;
  }

  @include on-screen(large) {
    transform: rotate(15deg);
  }
}
