@import './libs/veneer/src/styles/sass/application';

.container {
  @include property-on-screens('padding-right', 0, 15px, 2rem);
}

.headline {
  @extend %heading-4;
  @include property-on-screens('margin-bottom', 22px, 20px, 1.65rem);

  @include on-screen(medium) {
    @include heading-3;
  }
}

.text {
  @include property-on-screens('margin-bottom', 17px, 35px, 3.3rem);
}
