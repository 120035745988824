@import '../../../../../libs/veneer/src/styles/sass/application';

.footer {
  position: relative;
  z-index: 1;
  @include property-on-screens('padding', 45px 0 133px, 77px 0 161px, 2.8rem 0 1.7rem);
  background-color: var(--c--smart-blue);
  color: var(--c--brilliant-blue);
}

.container {
  @extend %grid;
  @include property-on-screens('row-gap', 29px, 21px, 2.4rem);
}

.logo-container {
  grid-column: -1 / 1;
  grid-row: 1;

  @include on-screen(medium) {
    grid-column: 1 / 5;
  }

  @include on-screen(large) {
    grid-column: span 3;
  }
}

.logo {
  display: block;
  @include property-on-screens('width', 211px, 266px, 11.95rem);
  max-width: 100%;
}

.navigation {
  display: flex;
  flex-direction: column;

  &__title {
    @extend %text-short-medium;
    @include property-on-screens('margin-bottom', 14px, 23px, 0.7rem);

    .navigation--additional & {
      @include property-on-screens('margin-bottom', 4px, 18px, 0.7rem);
    }
  }

  &__link {
    @extend %text-short;
    @include property-on-screens('margin-bottom', 4px, 4px, 0.15rem);
    text-decoration: none;
  }

  &--highlights {
    grid-column: span 4;

    @include on-screen(medium) {
      grid-column: 1 / auto;
    }

    @include on-screen(large) {
      grid-column: 4 / 6;
    }
  }

  &--service {
    grid-column: span 2;
    @include property-on-screens('padding-left', 0, 50px, 0);

    @include on-screen(large) {
      grid-column: 6 / 8;
    }
  }

  &--additional {
    grid-column: -1 / 1;

    div:nth-child(3) {
      margin-top: 1.7rem;
    }

    @include on-screen(medium) {
      grid-column: 5 / 7;
      grid-row: 1;
      justify-self: flex-end;
    }

    @include on-screen(large) {
      grid-column: 8 / 10;
      justify-self: flex-start;
    }
  }
}

.contact {
  grid-column: span 4;
  @include property-on-screens('margin-top', 0, -6px, 0);

  @include on-screen(medium) {
    grid-column: span 2;
    margin-top: -6px;
  }

  @include on-screen(large) {
    grid-column: 4 / span 6;
  }
}

.searchbar-container {
  display: flex;
  grid-column: -1 / 1;
  align-items: flex-start;
  @include property-on-screens('margin-bottom', 0, 47px, 0);

  @include on-screen(medium) {
    grid-column: span 4;
    grid-row: 2;
  }

  @include on-screen(large) {
    grid-column: 10 / 13;
    grid-row: 1;
    justify-content: flex-end;
  }
}

.searchbar {
  @include property-on-screens('width', 100%, 399px, 17.3rem);
}

.legal {
  grid-column: span 2;
  @include property-on-screens('margin-top', -3px, -12px, 0);

  @include on-screen(medium) {
    grid-column: span 1;
  }

  @include on-screen(large) {
    grid-column: span 3;
  }
}
