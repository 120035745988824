@import './libs/veneer/src/styles/sass/application';

.container {
  padding: 1px 0;
  background-color: var(--c--light-grey);
}

.inner {
  @extend %grid;
  grid-row-gap: 0;
  margin-top: var(--s--spacer-smallest);
  margin-bottom: var(--s--spacer-smallest);

  @include on-screen(large) {
    margin-top: var(--s--spacer-large);
    margin-bottom: var(--s--spacer-large);
  }
}

.headline {
  grid-column: 1/-1;
  @extend %heading-1;

  @include on-screen(large) {
    grid-column: span 10;
    padding-right: 10%;
  }
}

.intro {
  @extend %text-long;
  grid-column: 1/-1;
  @include property-on-screens('margin-top', 25px, 36px, 3.1rem);

  @include on-screen(medium) {
    @include intro;
  }

  @include on-screen(large) {
    grid-column: span 8;
  }
}

.buttons {
  display: flex;
  grid-column: 1/-1;
  gap: 10px;
  flex-wrap: wrap;
  @include property-on-screens('margin-top', 34px, 42px, 1.9rem);

  @include on-screen(medium) {
    flex-direction: row;
  }
}

.item-grid {
  display: grid;
  grid-column: 1/-1;
  @include property-on-screens('gap', 25px, 43px 28px, 3.75rem 2.5rem);
  @include property-on-screens('margin-top', 39px, 62px, 4.6rem);

  @include on-screen(medium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include on-screen(large) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.item {
  display: flex;
  gap: 0.95rem;

  &__icon {
    flex-shrink: 0;
  }

  &__title {
    @extend %text-short-medium;
  }

  &__subline {
    @extend %text-short;
  }
}
