@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
}

.inner {
  display: grid;
  grid-column: 1/-1;
  grid-gap: var(--s--grid-gap);
  grid-template-columns: repeat(5, minmax(0, 1fr));
  align-items: center;
  padding: 20px 17px 65px;
  background-color: var(--c--cosy-cashmere);

  @include on-screen(medium) {
    padding: 0 23px;
  }

  @include on-screen(large) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    padding: 0 0.75rem;
  }
}

.image {
  grid-column: 1/-1;
  aspect-ratio: 1.4;
  max-width: 371px;
  margin: 0 auto;

  @include on-screen(medium) {
    grid-column: span 2;
  }

  @include on-screen(large) {
    grid-column: span 3;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.content {
  display: flex;
  grid-column: 1/-1;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 38px 62px;

  @include on-screen(medium) {
    flex-direction: row;
    align-items: center;
    padding: 42px 0 46px;

    .with-image & {
      grid-column: span 3;
    }
  }

  @include on-screen(large) {
    margin-bottom: 1rem;
    padding-right: 5rem;
    padding-left: 5rem;

    .with-image & {
      grid-column: span 9;
      padding-left: 0;
    }
  }
}

.title {
  flex: 1 0 55%;
  color: var(--c--smart-blue);
}
