@import '../../../styles/sass/application';

.button {
  display: block;
  position: relative;
  border: 0;
  background: none;
  cursor: pointer;

  &.with-notification {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      @include property-on-screens('width', 7px, 9px, 0.55rem);
      @include property-on-screens('height', 7px, 9px, 0.55rem);
      border-radius: 50%;
      background-color: var(--c--feelgood-mint);
    }
  }

  &.with-label {
    display: flex;
    align-items: center;

    .icon {
      @include property-on-screens('width', 14px, 18px, 0.9rem);
      @include property-on-screens('height', 14px, 18px, 0.9rem);
    }
  }
}

.icon {
  @include property-on-screens('width', 27px, 27px, 1.2rem);
  @include property-on-screens('height', 27px, 27px, 1.2rem);
}

.label {
  @include property-on-screens('margin-left', 6px, 8px, 0.4rem);
  font-size: var(--fs--interaction);
}
