@import './libs/veneer/src/styles/sass/application';

.container {
  display: flex;
  padding: 20px 0;
  overflow-x: auto;
  border-bottom: 1px solid var(--c--intense-blue);

  @include on-screen(medium) {
    align-items: center;
    padding-right: 2px; // Prevent cut off by overflow
  }

  &.first {
    border-top: 1px solid var(--c--intense-blue);
  }
}

.col {
  display: flex;

  &--left {
    flex-direction: column;
    width: 106px;
    margin-right: 26px;
    border-right: 1px solid var(--c--middle-grey);

    @include on-screen(medium) {
      width: 122px;
      padding: 10px 0;
    }

    @include on-screen(large) {
      flex-direction: row;
      align-items: flex-end;
      width: 12rem;
      margin-right: 1rem;
      padding: 0;
      border: 0;
    }
  }

  &--right {
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 8px;

    @include on-screen(medium) {
      flex-direction: row;
      align-items: center;
      padding-bottom: 0;
    }
  }
}

.information {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 33px;
  padding-bottom: 8px;

  @include on-screen(medium) {
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 0;
  }
}

.controls {
  display: flex;
}

.size {
  @extend %display;
  margin-bottom: 13px;
  font-size: 40px;
  line-height: 1.1;

  @include on-screen(medium) {
    @include heading-4;
    margin-bottom: 5px;
  }

  @include on-screen(large) {
    margin-right: 4rem;
    margin-bottom: -0.15rem;
  }
}

.button {
  margin-left: 8px;

  @include on-screen(medium) {
    margin-left: 10px;
  }
}

.price-information {
  margin-bottom: 18px;

  @include on-screen(medium) {
    margin-right: 40px;
    margin-bottom: -3px;
  }
}

.delivery-status {
  margin-bottom: 34px;

  @include on-screen(medium) {
    align-self: flex-end;
    width: 185px;
    margin-bottom: 0;
  }

  @include on-screen(large) {
    align-self: auto;
    width: 10rem;
    margin-top: 1.1rem;
  }
}
