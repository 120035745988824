@import './libs/veneer/src/styles/sass/application';

.container {
  display: flex;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.6);
  backdrop-filter: blur(12px);
}

.close-to-click {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inner {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  @include property-on-screens('width', 100vw, 91vw, 52.5vw);
  @include property-on-screens('padding', 40px 26px, 55px 45px, 2rem 2.35rem);
  background-color: #fff;

  @include on-screen(medium) {
    border-radius: 4px;
  }
}

.close {
  position: absolute;
  top: 1.85rem;
  right: 2.1rem;
  @include property-on-screens('display', none, none, block);

  :global(> *) {
    @include on-screen(large) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.prompt-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem 0;

  :global {
    .headline {
      @include property-on-screens('padding-bottom', 20px, 15px, 0.85rem);
      @extend %heading-4;
      text-align: center;
    }

    .copy {
      max-width: 35.9rem;
      @include property-on-screens('margin-bottom', 32px, 25px, 1.85rem);
      @extend %text-short;
      text-align: center;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .prompt-input {
      @include property-on-screens('width', 100%, 55%, 48%);
    }

    .buttons {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
