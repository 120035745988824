@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  display: block;
  margin-top: var(--s--spacer-smallest);
  margin-bottom: var(--s--spacer-smallest);
}

.headline {
  margin-bottom: 24px;
  text-align: center;

  @include on-screen(medium) {
    margin-bottom: 36px;
  }

  @include on-screen(large) {
    margin-bottom: 2.4rem;
  }
}

.grid {
  @extend %grid;
  grid-column: 1/-1;
  grid-row-gap: 80px;
  margin: 0 0 80px;

  &__item {
    grid-column: 1/-1;

    @include on-screen(medium) {
      grid-column: span 3;
    }

    .filters-visible & {
      @include on-screen(large) {
        grid-column: span 4;
      }
    }
  }
}

.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner-container {
  @extend %grid;
  margin: 30px 0 0;
}

.divider {
  margin: 1.9rem -1.5rem 0.7rem;
  border-width: 1px 0 0;
  border-color: var(--c--middle-grey);
}

.filter-toggle {
  @extend %regular;
}

.filters {
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include property-on-screens('padding', 36px 25px 125px, 36px 25px 125px, 1.45rem 1.5rem);
  overflow-y: auto;
  background-color: var(--c--light-grey);

  @include on-screen(large) {
    position: static;
    z-index: auto;
    grid-column: span 3;
    order: 2;
    border-radius: 4px;
  }

  &--show {
    display: block;
  }

  &__top {
    @include property-on-screens('display', flex, flex, none);
    justify-content: space-between;
    margin-bottom: 38px;
    padding-bottom: 29px;
    border-bottom: 1px solid var(--c--middle-grey);
  }

  &__bottom {
    position: fixed;
    @include property-on-screens('display', flex, flex, none);
    bottom: 43px;
    left: 0;
    justify-content: center;
    width: 100%;
  }
}

.results {
  grid-column: 1/-1;
  padding-bottom: 3rem;
  border-bottom: 1px solid var(--c--middle-grey);

  @include on-screen(large) {
    margin-bottom: 3.5rem;
  }

  .filters-visible & {
    @include on-screen(large) {
      grid-column: span 9;
    }
  }
}

.dropdown {
  @include property-on-screens('margin-bottom', 10px, 10px, 0);

  :global label {
    @include below-screen(large) {
      @include subtext;
      font-weight: 400;
    }
  }

  @include on-screen(large) {
    text-transform: uppercase;
  }
}
