@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;

  grid-row-gap: 80px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-top: var(--s--spacer-small);
  margin-bottom: var(--s--spacer-medium);

  @include on-screen(medium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.item {
  &__image {
    position: relative;
    width: 100%;
    margin-bottom: 0.9rem;
    aspect-ratio: 1.56;
    background-color: var(--c--light-grey);

    &__inner {
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      inset: 0;
    }

    img {
      max-height: 85%;
      object-fit: contain;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }
}
