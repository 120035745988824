@import '../../../styles/sass/application';

.button {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  gap: 8px;
  background: none;
  cursor: pointer;
  @extend %subtext-regular;

  &:disabled {
    cursor: default;
  }
}
