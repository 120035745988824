.container {
  position: relative;
  overflow: hidden;

  .dropshadow {
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: transparent;
    box-shadow: 0px -6px 40px rgba(0, 0, 0, 0.07);
    pointer-events: none;
  }
}

.list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  & > div:last-child {
    border-bottom: 0;
  }
}
