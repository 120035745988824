@import '../../../styles/sass/application';

.text-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &--with-subline {
    align-items: flex-start;
  }

  &__icon {
    flex: 0 0 auto;

    &--subtext {
      width: 1.35rem;
      height: 1.35rem;
      margin-right: 0.35rem;
    }

    &--normal {
      width: 1.7rem;
      height: 1.7rem;
      margin-right: 0.35rem;
    }

    &--with-subline {
      @include property-on-screens('width', 39px, 28px, 2rem);
      @include property-on-screens('height', 39px, 28px, 2rem);
      @include property-on-screens('margin-top', 0, 0, 0.15rem);
      @include property-on-screens('margin-right', 13px, 9px, 1.2rem);
    }
  }

  &__label {
    display: inline-block;
    text-align: left;

    &--normal {
      @extend %textlink;
      line-height: 1.3;
    }

    &--subtext {
      @extend %subtext;
      line-height: 1.3;
    }

    &--with-subline {
      @extend %heading-4;

      @include on-screen(medium) {
        font-size: 24px;
      }

      @include on-screen(large) {
        font-size: 2rem;
      }
    }
  }

  &__subline {
    @extend %subtext;
    @extend %underline;
    @include property-on-screens('margin-top', 4px, 7px, 0.5rem);

    @include on-screen(medium) {
      font-size: 12px;
    }

    @include on-screen(large) {
      @include textlink;
    }
  }

  &--white {
    color: var(--c--white);
  }

  &--brilliant-blue {
    color: var(--c--brilliant-blue);
  }
}
