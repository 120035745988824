@import '../../../../../../libs/veneer/src/styles/sass/application';

.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -2px;
  gap: 2rem;
}

.link {
  display: flex;
  align-items: center;
  @extend %bold;
  gap: 3px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
