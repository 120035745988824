@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &--centered {
    margin-top: var(--s--spacer-large);
    margin-bottom: var(--s--spacer-large);
    color: var(--c--smart-blue);
    text-align: center;

    .headline,
    .textblock {
      @extend %intro--start;
      grid-column: 1/-1;
      margin: 0 auto;

      @include on-screen(large) {
        max-width: 76%;
      }
    }
  }
}

.headline {
  @include columns-on-screens(span 6, 2 / span 4, 3 / span 8);
  @extend %heading-4;
}

.textblock {
  @include columns-on-screens(span 6, 2 / span 4, 3 / span 8);
  @extend %rich-text;
  @extend %text-long;
}
