@import './libs/veneer/src/styles/sass/application';

.vacancy {
  display: block;

  &:not(:last-of-type) {
    margin-bottom: 2px;
  }

  &__container {
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include property-on-screens('margin-bottom', 50px, 50px, 3.25rem);

    @include below-screen(large) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__filter {
    @include property-on-screens('min-width', 100%, auto, 15rem);

    margin-bottom: 10px;
    @include on-screen(medium) {
      margin-bottom: 0;
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }

  &__button {
    @include property-on-screens('margin-left', 0, 20px, 1.5rem);
  }

  &__amount {
    @include below-screen(large) {
      order: 1;
      margin-bottom: 20px;
    }
  }

  &__filter-list {
    display: flex;

    @include below-screen(large) {
      order: 2;
    }

    @include below-screen(medium) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__headline {
    @include property-on-screens('margin-bottom', 10px, 18px, 0.9rem);
  }
}

.intro {
  grid-column: 1 / -1;
  margin-bottom: 3rem;
  @extend %heading-2;

  @include on-screen(large) {
    grid-column: span 10;
    margin-bottom: 9rem;
  }
}
