@import './libs/veneer/src/styles/sass/application';

.toast {
  display: flex;
  position: relative;
  @include property-on-screens('gap', 10px, 15px, 0.75rem);
  @include property-on-screens('padding', 38px 20px 32px, 30px 20px, 1.5rem 1rem);
  border-radius: 4px;
  @include font-size-on-screens(15px, 16px, 0.8rem);
  line-height: 1.35;
  cursor: pointer;

  &--normal {
    background-color: var(--c--brilliant-blue);
  }

  &--success {
    background-color: var(--c--feelgood-mint);
  }

  &--warning {
    background-color: #f79090;
  }
}

.icon {
  flex-shrink: 0;
  margin-top: -5px;
}

.title {
  padding-bottom: 0.1rem;
}

.close {
  position: absolute;
  top: 16px;
  right: 21px;
  width: 16px;
}

.bodytext {
  @extend %bold;
}
