@import '../../../../veneer/src/styles/sass/application';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--s--cart--single-row-height);
  padding: 0 var(--s--cart--mobile-horizontal-padding);
  border-radius: var(--s--cart--border-radius);
  background-color: var(--c--feelgood-mint);

  @extend %commerce-bold;
  text-transform: uppercase;

  @include on-screen(large) {
    padding: 0 var(--s--cart--single-row-horizontal-padding);
  }

  &__first {
    display: flex;
    align-items: center;
  }
}

.check {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border: 1px solid var(--c--intense-blue);
  border-radius: 12px;

  &__icon {
    width: 12px;
    margin-top: -2px;
    stroke-width: 3;
  }
}
