@import '../../../../../libs/veneer/src/styles/sass/application';

.header {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 var(--s--wrap);
  background: linear-gradient(180deg, rgba(241, 241, 241, 0.3) 36.79%, rgba(241, 241, 241, 0) 99.25%);
  transition: all 0.3s var(--ease-in-out-cubic);

  @include on-screen(large) {
    padding: 0 2.95rem 0 var(--s--wrap);
  }

  &__fixed {
    background: white;
    box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.25);

    &--top {
      transform: translate(0, -45px);
    }
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 45px;
  border-bottom: 1px solid var(--c--middle-grey);

  @extend %wrapped-content;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;

  @extend %wrapped-content;
}

.controls {
  display: flex;
  align-items: center;
}

.searchbar {
  display: none;

  @include on-screen(large) {
    display: block;
    flex: 1;
    width: 22rem;
  }
}

.icon-buttons {
  display: flex;
  align-items: center;
  padding: 0 1.05rem;
  @include property-on-screens('gap', 0.25rem, 0.75rem, 0.75rem);

  &__search {
    @include property-on-screens('display', block, block, none);
  }

  &__wishlist {
    @include property-on-screens('display', none, block, block);
  }

  &__cart {
    @include property-on-screens('display', block, none, block);
  }
}

.locale-switch {
  @include property-on-screens('padding-right', 10px, 1.25rem, 1.25rem);
}

.logo {
  display: block;
  @include property-on-screens('width', 149px, 191px, 17.5rem);
  @include property-on-screens('margin-top', 1px, 1px, 0px);
}

.nav-btn {
  @include property-on-screens('padding-left', 0, 15px, 2rem);
  @include property-on-screens('margin-left', 0, 7px, 1rem);

  @include on-screen(medium) {
    border-left: 1px solid var(--c--middle-grey);
  }
}
