@import './libs/veneer/src/styles/sass/application';

.container {
  padding: 1px 0;
  background-color: var(--c--cosy-cashmere);
}

.inner {
  @extend %grid;
  margin-top: var(--s--spacer-large);
  margin-bottom: var(--s--spacer-large);

  > div {
    grid-column: 1 / -1;
  }
}
