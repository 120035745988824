.video-container {
  position: relative;
  aspect-ratio: 1.02;
  cursor: none !important;
  mix-blend-mode: darken;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
}

.image {
  aspect-ratio: 1.02;
  object-fit: contain;
}

.cursor {
  &--hover {
    display: none;
    position: absolute;
    pointer-events: none;

    @media (any-pointer: fine) {
      display: block;
    }
  }

  &--static {
    position: absolute;
    bottom: 7%;
    left: 7%;

    @media (any-pointer: fine) {
      display: none;
    }
  }
}
