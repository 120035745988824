@import '../../../../../../veneer/src/styles/sass/application';

.container {
  position: relative;
  text-transform: capitalize;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--c--light-grey);
}

.table {
  width: 100%;

  tbody {
    color: rgba(var(--c--intense-blue-rgb), 0.5);
    @extend %commerce;

    tr:last-child td {
      padding-bottom: 0.9rem;
    }
  }

  tr {
    td {
      padding: 0.25rem 0;
    }

    td:first-child {
      max-width: 250px;
      padding-left: var(--s--cart--vertical-padding);
      @extend %bold;

      @include on-screen(large) {
        padding-left: var(--s--summary--horizontal-padding);
      }
    }
    td:nth-child(2) {
      padding-right: var(--s--cart--vertical-padding);
      text-align: right;

      @include on-screen(large) {
        padding-left: var(--s--summary--horizontal-padding);
      }
    }
  }

  tfoot {
    td {
      padding: 0.9rem 0;
      border-top: var(--border--cart-divider);
      @extend %text-short-medium;
    }
  }
}

.price {
  justify-content: flex-end;
}
