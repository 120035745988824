@import './libs/veneer/src/styles/sass/application';

$desktop-lg: 1680px;

.container {
  padding-top: var(--s--header-height);
  padding-bottom: 65px;
  @include property-on-screens('padding-bottom', 65px, var(--s--spacer-smallest), var(--s--spacer-medium));
  background-color: var(--c--light-grey);
}

.inner {
  display: grid;
  grid-gap: 57px;
  @extend %wrapped-content;

  @include on-screen(large) {
    grid-template-columns: 49% minmax(0, 1fr);
    margin-right: calc(var(--s--wrap) + var(--s--sidebar-width));
  }
}

.info-container {
  padding: 0 var(--s--wrap);

  @include on-screen(large) {
    padding: 2.5rem 0 0;
  }

  @media (min-width: $desktop-lg) {
    padding-top: 7.25rem;
  }
}

.headline {
  @extend %heading-2;
  @include property-on-screens('padding-bottom', 36px, 45px, 2.25rem);

  width: 100%;
}

.intro {
  @extend %intro;
  @include property-on-screens('width', 100%, 80%, 70%);
  @include property-on-screens('margin-bottom', 19px, 28px, 1.4rem);

  @media (min-width: #{map-get($screensizes, large)}) and (max-width: calc($desktop-lg - 1px)) {
    width: 80%;
  }
}

.cta-area {
  @include property-on-screens('margin-top', 31px, 36px, 2.45rem);
}

.commerce-information {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include property-on-screens('margin-top', 50px, 59px, 3.45rem);

  @include on-screen(medium) {
    flex-direction: row;
    align-items: flex-end;
  }

  & > *:first-child {
    @include property-on-screens('margin-right', 0, 90px, 90px);
    @include property-on-screens('margin-bottom', 7px, 0, 0);
  }
}

.bookmark {
  @include on-screen(medium) {
    order: 4;
  }
}

.only-mobile {
  @include on-screen(medium) {
    display: none;
  }
}

.pill-container {
  @include property-on-screens('margin-bottom', 23px, 22px, 41px);

  > div {
    @include property-on-screens('margin-right', 7px, 8px, 9px);
    @include property-on-screens('margin-bottom', 7px, 8px, 9px);
  }
}

.pill {
  cursor: pointer;
}

.dropdown-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 10px;
  @include property-on-screens('width', 100%, 80%, 70%);
  margin-bottom: 20px;

  @include on-screen(medium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: #{map-get($screensizes, large)}) and (max-width: calc($desktop-lg - 1px)) {
    width: 80%;
  }

  .reset-button__container {
    display: flex;
    grid-column: 1 / -1;
    justify-content: flex-end;
  }

  .reset-button {
    @extend %subtext;
    text-decoration: underline;
    cursor: pointer;
  }
}

.mandrel-container {
  @include property-on-screens('margin-bottom', 45px, 30px, 30px);

  > div {
    @include property-on-screens('margin-right', 30px, 30px, 37px);
    margin-bottom: 15px;
  }
}

.delivery-status {
  display: flex;
}

.delivery {
  @include property-on-screens('margin-right', 1rem, 1rem, 2rem);
}

.sku {
  @extend %subtext-regular;
}
