@import '../../../styles/sass/application';

.accordion {
  display: block;
  overflow: hidden;
  border-bottom: 1px solid var(--c--intense-blue);

  &--large {
    &:first-child {
      border-top: 1px solid var(--c--intense-blue);
    }
  }

  &--small {
    border-color: var(--c--middle-grey);

    @include on-screen(large) {
      border: 0;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .accordion--large & {
    @include property-on-screens('padding', 23px 0, 32px 0, 2.5rem 0);
  }
}

.title {
  .accordion--large & {
    @extend %subheading;
  }

  .accordion--small & {
    @extend %regular;
    @include property-on-screens('padding', 32px 0, 32px 0, 1.25rem 0);
    @include font-size-on-screens(15px, 15px, 0.75rem);

    @include on-screen(large) {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

.icon {
  margin-left: 30px;
}

.content {
  @extend %rich-text;
  line-height: 1.55;

  .accordion--large & {
    @include property-on-screens('padding', 0 0 30px, 0 0 60px, 0 0 4.5rem 1.5rem);
    @include on-screen(large) {
      max-width: 77%;
    }
  }

  .accordion--small & {
    @include property-on-screens('padding-bottom', 30px, 30px, 1.5rem);
  }
}
