.video {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &--hide {
    opacity: 0;
    pointer-events: none;
  }
}
