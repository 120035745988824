@import '../../../styles/sass/application';

.container {
  margin: var(--s--spacer-medium) var(--s--wrap);
  @extend %wrapped-content;

  @include on-screen(large) {
    margin-right: calc(var(--s--wrap) + var(--s--sidebar-width));
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  margin-right: calc(var(--s--grid-gap) * -0.5);
  margin-left: calc(var(--s--grid-gap) * -0.5);
  row-gap: 30px;

  @include on-screen(medium) {
    row-gap: 80px;
  }
}

.fact {
  flex: 0 0 auto;
  width: 100%;
  padding-right: calc(var(--s--grid-gap) * 0.5);
  padding-left: calc(var(--s--grid-gap) * 0.5);

  @include on-screen(large) {
    width: 33.33%;
  }
}
