@import './libs/veneer/src/styles/sass/application';

.container {
  margin-bottom: 0.35rem;

  .copy {
    @include on-screen(large) {
      margin-bottom: 1.15rem;
    }
  }
}

.retry-label {
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
  @extend %bold;
}

.prompt-input {
  @include property-on-screens(
    'margin-bottom',
    calc(32px - var(--s--input-error)),
    calc(38px - var(--s--input-error)),
    calc(2.1rem - var(--s--input-error))
  );
}
