.logo {
  width: 100%;
  height: auto;
  background-color: transparent;
}

.brilliant-blue {
  path,
  g {
    stroke: none;
    fill: var(--c--brilliant-blue);
  }
}

.shiny-yellow {
  path,
  g {
    stroke: none;
    fill: var(--c--shiny-yellow);
  }
}

.feelgood-mint {
  path,
  g {
    stroke: none;
    fill: var(--c--feelgood-mint);
  }
}

.smart-blue {
  path,
  g {
    stroke: none;
    fill: var(--c--smart-blue);
  }
}

.black {
  path,
  g {
    stroke: none;
    fill: var(--c--black);
  }
}

.white {
  path,
  g {
    stroke: none;
    fill: var(--c--white);
  }
}
