@import './libs/veneer/src/styles/sass/application';

.black-backdrop {
  display: flex;
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.6);
  backdrop-filter: blur(12px);
}
