@import './libs/veneer/src/styles/sass/application';

.filters {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 1rem;

  @include on-screen(large) {
    flex-direction: row;
  }
}
