@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@mixin generateColors($colors) {
  // Colors
  @each $color, $value in $colors {
    @if str-slice(#{$value}, 1, 1) == '#' {
      --c--#{$color}: #{$value};
    } @else {
      --c--#{$color}: var(--#{$value});
    }
  }

  // RGB Colors
  @each $color, $value in $colors {
    @if str-slice(#{$value}, 1, 1) == '#' {
      --c--#{$color}-rgb: #{hexToRGB($value)};
    } @else {
      --c--#{$color}-rgb: var(--#{$value}-rgb);
    }
  }
}
