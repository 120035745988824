@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  position: relative;
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-medium);

  @include below-screen(medium) {
    margin-right: 0;
    margin-left: 0;
  }

  &--article {
    .item-container {
      @include columns-on-screens(span 6, 2 / span 4, 3 / span 8);
    }
  }
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.item-container {
  position: relative;
  aspect-ratio: 2.04;
  grid-column: 1/-1;
}

.item {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.youtube-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
