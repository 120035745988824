@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: var(--s--spacer-large);
  margin-bottom: var(--s--spacer-large);
}

.contact-teaser {
  grid-column: 1 / -1;
}

.button {
  @include property-on-screens('margin-top', 25px, 35px, 2.9rem);
}
