@import './libs/veneer/src/styles/sass/application';

.container {
  overflow: hidden;
  background-color: var(--c--smart-blue);
}

.inner {
  @extend %wrapped-content;
  @include property-on-screens(
    'padding',
    65px var(--s--wrap),
    77px var(--s--wrap) 85px,
    6.05rem calc(7rem + var(--s--sidebar-width)) 6.05rem 7rem
  );
  color: var(--c--brilliant-blue);
}

.headline {
  @extend %subheading;
  @include property-on-screens('margin-bottom', 22px, 36px, 2.7rem);
  text-align: center;

  @include below-screen(medium) {
    font-size: 12px;
  }

  @include on-screen(large) {
    @include topline;
  }
}

.items {
  @extend %heading-4;

  @include on-screen(large) {
    @include tagcloud;
  }
}
