@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;

  align-items: center;
  margin-top: var(--s--spacer-large);
  margin-bottom: var(--s--spacer-large);
}

.column {
  grid-column: 1 / -1;

  .container--text-last & {
    &--content {
      order: 2;
    }

    &--media {
      order: 1;
    }
  }

  @include on-screen(large) {
    .container--text-first & {
      &--content {
        grid-column: 2 / span 5;
        padding-right: 3rem;
      }

      &--media {
        grid-column: 7 / span 5;
      }
    }

    .container--text-last & {
      &--content {
        grid-column: 7 / span 5;
        padding-left: 3rem;
      }

      &--media {
        grid-column: 2 / span 5;
      }
    }
  }
}

.title {
  margin-bottom: 28px;

  @include on-screen(large) {
    margin-bottom: 2rem;
  }
}

.text {
  @extend %rich-text;
}

.media {
  @include vertical-media-item;
}
