@import './libs/veneer/src/styles/sass/application';

.container {
  display: block;
  padding-top: var(--s--spacer-smaller);
  padding-bottom: var(--s--spacer-small);
  background-color: var(--c--smart-blue);
}

.inner {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
  transition: height 1000ms ease-in-out;

  @include on-screen(large) {
    width: calc(100% - var(--s--sidebar-width));
    margin-right: var(--s--sidebar-width);
  }
}

.slide--inner {
  @extend %grid;
  grid-row-gap: 0;
  height: 100%;
  margin-right: var(--s--wrap);
  padding-bottom: 10px;
}

.headline {
  grid-column: 1/-1;
  @include property-on-screens('margin-bottom', 45px, 60px, 4.3rem);
  color: var(--c--brilliant-blue);

  @include on-screen(large) {
    grid-column: 2/9;
  }
}

.cta {
  grid-column: 1/-1;
  @include property-on-screens('margin-bottom', 50px, 65px, 5.55rem);
  color: var(--c--feelgood-mint);

  @include on-screen(large) {
    grid-column: 2/9;
  }
}

.pill {
  width: 100%;
  margin: 10px 4px;
  opacity: 1;
  line-height: 1;
  text-transform: uppercase;
  @include property-on-screens('margin', 5px 0, 5px 4px, 10px 4px);

  @include on-screen(medium) {
    @include legend;
    width: auto;
    opacity: 1;
  }

  @include on-screen(large) {
    @include legend;
    width: auto;
    opacity: 1;
  }

  &__container {
    display: flex;
    grid-column: 1/-1;
    flex-wrap: wrap;
    margin: -10px -4px;

    @include on-screen(large) {
      grid-column: 2/12;
    }
  }
}

.contact {
  display: flex;
  grid-column: 1/-1;
  flex-direction: column;
  color: var(--c--brilliant-blue);

  &__headline {
    margin-bottom: 63px;
    @include property-on-screens('margin-bottom', 25px, 25px, 3.15rem);
  }

  &__button {
    max-width: fit-content;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    &:last-of-type {
      @include property-on-screens('margin-bottom', 50px, 50px, 0);
    }
  }

  &__divider {
    grid-column: 7;
    width: 1px;
    height: 100%;
    @include property-on-screens('margin-left', -13px, -20px, -1.25rem);
    background-color: var(--c--brilliant-blue);

    @include below-screen(large) {
      display: none;
    }
  }

  @include on-screen(large) {
    grid-column: 2/6;
    &--full {
      grid-column: 1/-1;
    }
  }
}

.back {
  @include property-on-screens('margin-bottom', 50px, 50px, 7.55rem);
}

.hubspot-form {
  grid-column: 1/-1;
  height: 100%;
  margin-top: 17px;
  margin-right: var(--s--wrap);
  padding-bottom: 1rem;

  @include on-screen(large) {
    grid-column: 8/-1;
  }
}
