@import 'mixins/screen';

%wrap {
  margin-right: var(--s--wrap);
  margin-left: var(--s--wrap);
  @extend %wrapped-content;

  @include on-screen(large) {
    margin-right: calc(var(--s--wrap) + var(--s--sidebar-width));
  }
}

%grid {
  display: grid;
  grid-gap: var(--s--grid-gap);
  grid-template-columns: repeat(6, minmax(0, 1fr));
  @extend %wrap;

  @include on-screen(large) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

%wrapped-content {
  @media only screen and (min-width: 1921px) {
    max-width: calc(1920px - var(--s--wrap) * 2);
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

%page-start {
  @include property-on-screens('padding-top', 90px, 120px, 10rem);
}
