@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  @include property-on-screens('grid-template-columns', 1fr, 1fr, 1fr);
  margin-bottom: 5rem;
}

.list {
  display: grid;
  @include property-on-screens('grid-template-columns', 1fr, repeat(2, minmax(0, 1fr)), repeat(3, minmax(0, 1fr)));
  @include property-on-screens('gap', 30px, 30px, 2.75rem);
}
