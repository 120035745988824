.inner {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
}

.wrapper {
  position: absolute;
  inset: 0;
  background-position: center;
  background-size: cover;

  &:hover .play {
    background-color: #f00;
  }
}

.active .play {
  display: none;
}

.play {
  z-index: 1;
  width: 70px;
  height: 46px;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  border-radius: 14%;
  opacity: 0.8;
  background-color: #212121;

  &,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &::before {
    content: '';
    border-width: 11px 0 11px 19px;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
  }
}

.iframe {
  width: 100%;
  height: 100%;
}
