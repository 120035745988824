@import './libs/veneer/src/styles/sass/application';

.container {
  &--minimal {
    display: flex;
  }

  &--horizontal,
  &--maximal {
    display: flex;
    align-items: flex-end;

    .price-tag {
      flex-direction: column;
      align-items: flex-start;
      order: 0;

      &__vat {
        padding-top: 5px;
      }
    }

    .price-type {
      order: 1;
      padding-bottom: 15px;
    }
  }

  &--maximal {
    .price-tag {
      align-items: flex-end;
    }
  }
}

.price-type {
  @extend %subtext-regular;
  margin-bottom: 6px;
}

.price-pdp {
  opacity: 0.5;

  &--cart {
    @include font-size-on-screens(1rem, 0.9rem, 0.9rem);
  }
}

.price-tag {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  &--pdp {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  &__vat {
    @extend %subtext;
    margin-right: 10px;

    &--cart {
      @include font-size-on-screens(1rem, 0.9rem, 0.9rem);
    }
  }

  &__shipping {
    text-decoration: underline;
  }

  &__price {
    margin-right: 10px;

    &--cart {
      @extend %bold;
      @include font-size-on-screens(1rem, 0.9rem, 0.9rem);
    }

    &--commerce-bold {
      @extend %commerce-bold;
    }

    &--smallest {
      @extend %commerce-bold;
      @include font-size-on-screens(1rem, 0.75rem, 0.75rem);
    }

    &--small {
      @extend %text-short-medium;
    }

    &--large {
      @extend %price;

      .container--minimal & {
        margin-bottom: -3px;
      }
    }

    &--pdp {
      @extend %heading-3;
    }
  }

  &__old-price {
    margin-right: 10px;
    line-height: initial;
  }

  &__discount-price {
    color: var(--c--status-red);
  }
}
