@import '../../../styles/sass/application';

.inner {
  @extend %button-text;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: var(--s--ui-element-height);
  @include property-on-screens('padding', 0 30px, 0 34px, 0 2.75rem);
  overflow: hidden;
  border-radius: calc(var(--s--ui-element-height) / 2);

  color: var(--c--smart-blue);
  white-space: nowrap;

  cursor: pointer;

  &--brilliant-blue {
    background: var(--c--brilliant-blue);
  }

  &--feelgood-mint {
    background: var(--c--feelgood-mint);
  }

  &--shiny-yellow {
    background: var(--c--shiny-yellow);
  }

  &--white {
    background: var(--c--white);
  }

  &--disabled,
  &--loading {
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;

    &::after {
      content: '\A';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: not-allowed;
    }
  }

  &--disabled {
    opacity: 0.35 !important;
  }

  &--small {
    @include property-on-screens('padding', 0 15px, 0 15px, 0 1rem);
    @include property-on-screens('height', 49px, 47px, 2.1rem);
  }
}
