@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: var(--s--spacer-smallest);
  margin-bottom: var(--s--spacer-smallest);
}

.inner {
  grid-column: 1 / -1;
  @include property-on-screens('padding', 2.3rem 1.75rem, 2.3rem 2.75rem, 2.3rem 3.75rem);
  background-color: var(--c--cosy-cashmere);
}

.headline {
  margin-bottom: 1.6rem;
  @extend %heading-3;
  color: var(--c--smart-blue);
}
.step-1,
.step-2 {
  @include property-on-screens('min-height', 133px, 68px, 4.15rem);
}

.step-2 {
  overflow: hidden;
}

.step-1,
.form {
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @include on-screen(medium) {
    flex-direction: row;
    width: auto;
  }

  &__input {
    min-width: 20vw;
  }
}
