@import '../../../styles/sass/application';

.inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  transition: background-color 0.2s ease, color 0.2s ease;
  border: 1px solid var(--c--intense-blue);
  border-radius: calc(var(--s--ui-element-height) / 2);
  font-size: var(--fs--interaction);
  white-space: nowrap;

  &.active {
    background-color: var(--c--intense-blue);
    color: var(--c--white);
  }

  &.interactive {
    cursor: pointer;

    &:hover {
      border-color: var(--c--smart-blue);
      background-color: var(--c--smart-blue);
      color: var(--c--white);
    }
  }

  &.disabled {
    opacity: 0.25;
    cursor: default;
  }

  &.standard {
    height: var(--s--ui-element-height);
  }

  &.small {
    @include property-on-screens('height', 50px, 50px, 2.5rem);
    @extend %subtext-regular;
  }

  &.brilliant-blue {
    border-color: var(--c--brilliant-blue);
    background-color: var(--c--brilliant-blue);
  }

  &.feelgood-mint-outline {
    border-color: var(--c--feelgood-mint);
    background-color: transparent;
    color: var(--c--feelgood-mint);

    &.active {
      background-color: var(--c--feelgood-mint);
      color: var(--c--smart-blue);
    }

    &.interactive:hover {
      border-color: var(--c--feelgood-mint);
      background-color: var(--c--feelgood-mint);
      color: var(--c--smart-blue);
    }
  }
}
