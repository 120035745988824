@import '../../../styles/sass/application';

.icon-wrapper {
  display: inline-block;
  transform-origin: center;
  transition: transform 0.6s ease;
  line-height: 1;

  &.rotate--90 {
    transform: rotate(90deg);
  }

  &.rotate--180 {
    transform: rotate(180deg);
  }

  &.rotate--270 {
    transform: rotate(270deg);
  }
}

.filled {
  display: block;
}

.unstyled {
  display: block;
}

@each $name, $hex in $colors {
  .#{$name} {
    svg {
      width: 100%;
      height: auto;
      background-color: transparent;
      line-height: 1;

      :global(*) {
        transition: fill 0.4s ease-in-out;
        stroke: $hex;
        fill: transparent;
      }
    }

    :global(svg.fill *),
    :global(.fill > *),
    :global(.fill) {
      stroke: transparent;
      fill: $hex;
    }

    :global(svg.fill-and-stroke *),
    :global(.fill-and-stroke > *),
    :global(.fill-and-stroke) {
      stroke: $hex;
      fill: $hex;
    }
  }
  .filled.#{$name} {
    :global(svg *) {
      stroke: transparent;
      fill: $hex;
    }
  }
}
