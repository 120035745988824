@import './libs/veneer/src/styles/sass/application';

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  img {
    display: block;
  }
}

.gallery {
  position: relative;
  margin-bottom: 20px;

  &__image {
    width: 100%;
    aspect-ratio: 1.03;
    object-fit: contain;
  }

  &__pagination {
    display: flex;
    position: absolute;
    z-index: 1;
    bottom: 59px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid var(--c--intense-blue);

    div {
      position: relative;
      width: 30px;
      height: 3px;
      background-color: var(--c--intense-blue);
    }
  }
}

.thumbs {
  // display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @include below-screen(medium) {
    display: none;
  }

  :global .swiper-wrapper {
    align-items: center;
    justify-content: center;
  }

  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__pagination {
    max-width: 100%;
    margin-top: 12px;
    margin-right: auto;
    margin-left: auto;

    div {
      position: relative;
      height: 2px;
      background-color: var(--c--intense-blue);
    }
  }
}
