@import '../../../styles/sass/application';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 5rem;
  font-weight: normal;

  &--smart-blue {
    --hs-form-color: var(--c--smart-blue);
    --hs-form-color-rgb: var(--c--smart-blue-rgb);
    --hs-form-field-bg: rgba(var(--hs-form-color-rgb), 0.2);
    --hs-form-submit-bg: var(--c--feelgood-mint);
    --hs-form-submit-color: var(--c--smart-blue);
    --hs-form-check-color: var(--c--feelgood-mint);
  }

  &--brilliant-blue {
    --hs-form-color: var(--c--brilliant-blue);
    --hs-form-color-rgb: var(--c--brilliant-blue-rgb);
    --hs-form-field-bg: rgba(var(--hs-form-color-rgb), 0.2);
    --hs-form-submit-bg: var(--c--brilliant-blue);
    --hs-form-submit-color: var(--c--smart-blue);
    --hs-form-check-color: var(--c--feelgood-mint);
  }

  &--shiny-yellow {
    --hs-form-color: var(--c--shiny-yellow);
    --hs-form-color-rgb: var(--c--shiny-yellow-rgb);
    --hs-form-field-bg: rgba(var(--hs-form-color-rgb), 0.2);
    --hs-form-submit-bg: var(--c--shiny-yellow);
    --hs-form-submit-color: var(--c--smart-blue);
    --hs-form-check-color: var(--c--feelgood-mint);
  }

  &--feelgood-mint {
    --hs-form-color: var(--c--feelgood-mint);
    --hs-form-color-rgb: var(--c--feelgood-mint-rgb);
    --hs-form-field-bg: rgba(var(--hs-form-color-rgb), 0.2);
    --hs-form-submit-bg: var(--c--feelgood-mint);
    --hs-form-submit-color: var(--c--smart-blue);
    --hs-form-check-color: var(--c--feelgood-mint);
  }

  &--white {
    --hs-form-color: var(--c--smart-blue);
    --hs-form-color-rgb: var(--c--white-rgb);
    --hs-form-field-bg: var(--c--white);
    --hs-form-submit-bg: var(--c--shiny-yellow);
    --hs-form-submit-color: var(--c--smart-blue);
    --hs-form-check-color: var(--c--feelgood-mint);
  }

  &--dark {
    --hs-form-color: var(--c--intense-blue);
    --hs-form-field-bg: var(--c--white);
    @include property-on-screens('padding', 15px, 40px, 50px);
    background-color: var(--c--light-grey);
  }

  .loader {
    width: 5rem;
    height: 5rem;

    &__container {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &__stop {
      stop-color: var(--c--intense-blue);
    }
  }

  .hubspot-form-loading {
    opacity: 0;
    pointer-events: none;
  }
}

.container :global .hs-form {
  line-height: 1.25;

  h2,
  h3,
  h4,
  h5 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  fieldset {
    display: grid;
    position: relative;
    max-width: 100%;
    @include property-on-screens('margin-bottom', 16px, 10px, 10px);

    &.form-columns-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &.form-columns-2 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: calc(24px - var(--s--input-error)) 25px;

      @include on-screen(medium) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: calc(24px - var(--s--input-error));
      }

      @include on-screen(large) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: 100%;
        row-gap: calc(1.6rem - var(--s--input-error));
      }
    }

    div.hs-form-field {
      width: 100%;
      //@include property-on-screens('width', 100%, calc(50% - 13px), calc(50% - 13px));

      & + .hs-form-field {
        @include property-on-screens('margin-top', 24px, 0, 0);
        //@include property-on-screens('margin-left', 0, 26px, 26px);
      }
    }

    label {
      display: inline-block;
      color: var(--hs-form-color);
      @include font-size-on-screens(14px, 15px, 15px);
    }

    legend {
      display: none !important;
    }

    div.hs-input {
      width: auto;
    }

    input,
    select,
    textarea,
    textarea.hs-input {
      color: var(--hs-form-color);
      @include font-size-on-screens(14px, 15px, 15px);
      font-weight: normal;
      width: 100%;

      &::placeholder {
        color: rgba(var(--hs-form-color-rgb), 0.5);
      }
    }

    select,
    textarea,
    input {
      &:not([type='checkbox']):not([type='radio']) {
        width: 100%;
        height: 59px;
        padding: 18px 20px;
        border: 0;
        border-radius: 4px;
        background-color: var(--hs-form-field-bg);
        line-height: 1.5;
      }
    }

    input[type='checkbox'],
    input[type='radio'] {
      position: absolute;
      opacity: 0;

      & + span {
        display: flex;
        position: relative;
        margin-left: 0;
        padding: 0 0 0 30px;
        color: var(--hs-form-color);
        line-height: 20px;
        cursor: pointer;

        .invalid & {
          color: var(--c--status-red);
        }

        @include below-screen(medium) {
          display: inline-flex;
        }
      }

      & + span p {
        flex: 1 1 calc(100% - 30px);
        @include below-screen(medium) {
          flex: 1 1 91%;
        }
      }

      & + span::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background: var(--c--white);
        vertical-align: text-top;

        .invalid & {
          box-shadow: 0 0 0 1px var(--c--status-red);
        }

        @include below-screen(medium) {
          flex: 0 0 auto;
        }
      }

      &:focus-visible + span::before {
        box-shadow: 0 0 0 1px var(--hs-form-color);
      }

      &:disabled + span {
        opacity: 0.5;
      }

      &:disabled + span::before {
        opacity: 0.5;
      }

      &:checked + span::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 15px;
        height: 15px;
        background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjE1IiB2aWV3Qm94PSIwIDAgMTUgMTUiIHdpZHRoPSIxNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMwMDJkNWYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyLjUiPjxwYXRoIGQ9Im0xMi4wOTc3IDIuNzY3NzctOS4yOTk3NyA5LjI5OTczIi8+PHBhdGggZD0ibTIuNzY3NzcgMi45NTQxIDkuMzYwNjMgOS4zNjA2Ii8+PC9nPjwvc3ZnPg==');
        box-shadow: none;
      }
    }

    input[type='radio'] {
      & + span::before {
        border-radius: 100%;
      }

      &:checked + span::after {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: var(--hs-form-color);
        box-shadow: none;
      }
    }
  }

  .field {
    @include property-on-screens('margin-bottom', 12px, 15px, 15px);
  }

  .input,
  .field {
    height: auto;
    margin-right: 0;
    padding: 0;

    label {
      margin-bottom: 9px;
    }
  }

  .hs-form-field {
    position: relative;

    & > label {
      font-weight: 600;
    }

    &.hs-phone .input {
      height: auto;
      .hs-fieldtype-intl-phone {
        height: auto;
      }
    }

    &:only-child {
      width: 100%;
    }

    .hs-error-msg {
      margin-top: 9px;
      margin-bottom: 0;
      color: var(--c--status-red);
      @include font-size-on-screens(11px, 15px, 15px);
    }

    .hs-field-desc {
      display: none;
    }
  }

  .hs-input {
    width: 100%;

    &.hs-fieldtype-textarea {
      resize: vertical;
    }
  }

  .hs-fieldtype-textarea .input {
    height: auto;
  }

  .hs-fieldtype-phonenumber .input {
    height: 142px;
    @include property-on-screens('height', 142px, 59px, 59px);
  }

  .hs-fieldtype-intl-phone {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;

    select {
      @include property-on-screens('width', 100%, calc(40% - 12px), calc(40% - 12px));
      @include property-on-screens('margin-bottom', 24px, 0, 0);
    }
    input {
      @include property-on-screens('width', 100%, calc(60% - 12px), calc(60% - 12px));
      @include property-on-screens('margin-left', 0, auto, auto);
    }
  }

  a {
    text-decoration: underline;
  }

  .hs-richtext {
    color: var(--hs-form-color);
    @include font-size-on-screens(14px, 15px, 15px);
    line-height: 1.2;
  }

  .legal-consent-container {
    .hs-form-booleancheckbox-display {
      display: flex;
      align-items: center;
      margin: 1rem 0;
    }
    .hs-form-field {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  select {
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg fill="none" height="13" viewBox="0 0 14 13" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m13.3014 3.69067-5.90692 5.54857-5.94585-5.58514" stroke="%2378dcff" stroke-width="1.5"/></svg>');
    appearance: none;
    background-repeat: no-repeat;
    background-position: calc(100% - 1.35rem) center;
    background-size: 13px 12px;
  }

  .hs-fieldtype-booleancheckbox .input {
    height: auto;
  }

  .hs-fieldtype-booleancheckbox .hs-error-msgs {
    bottom: 0;
    left: 30px;
  }

  .hs-fieldtype-radio .inputs-list {
    display: flex;

    .hs-form-radio:not(:last-of-type) {
      margin-right: 1rem;
    }

    .hs-form-radio label {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .hs-fieldtype-radio .input {
    height: auto;
  }

  .hs-submit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include property-on-screens('margin-top', 25px, 25px, 2rem);

    .actions {
      display: flex;
      align-items: center;
      width: 100%;

      .submit {
        @extend %button-text;
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 196px;
        height: var(--s--ui-element-height);
        margin-left: auto;
        @include property-on-screens('padding', 0 30px, 0 34px, 0 2.75rem);
        overflow: hidden;
        transition: transform 400ms ease-in-out;
        border: 0;
        border-radius: calc(var(--s--ui-element-height) / 2);

        background-color: var(--hs-form-submit-bg);
        color: var(--hs-form-submit-color);

        white-space: nowrap;

        cursor: pointer;

        &--left {
          margin-left: 0;
        }

        &--center {
          margin: 0 auto;
        }

        &--smart-blue {
          background: var(--c--smart-blue);
          color: var(--c--brilliant-blue);
        }

        &--brilliant-blue {
          background: var(--c--brilliant-blue);
          color: var(--c--smart-blue);
        }

        &--feelgood-mint {
          background: var(--c--feelgood-mint);
          color: var(--c--smart-blue);
        }

        &--shiny-yellow {
          background: var(--c--shiny-yellow);
          color: var(--c--smart-blue);
        }

        &--white {
          background: var(--c--white);
          color: var(--c--smart-blue);
        }
      }
    }
  }

  .hs-main-font-element {
    @include font-size-on-screens(14px, 15px, 15px);
    line-height: 1.2;
  }

  .hs-form-required {
    margin-left: 3px;
    color: var(--c--feelgood-mint);
  }
}

.container :global .submitted-message {
  color: var(--hs-form-color);
  @extend %heading-4;
}

.submit-message {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: var(--hs-form-color);
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    @include property-on-screens('width', 50px, 50px, 3.7rem);
    @include property-on-screens('height', 50px, 50px, 3.7rem);
    @include property-on-screens('margin-bottom', 25px, 30px, 1.65rem);
    border-radius: 100%;
    background-color: var(--hs-form-check-color);
  }

  &__icon {
    @include property-on-screens('width', 32px, 32px, 1.6rem);
    @include property-on-screens('height', 25px, 25px, 1.25rem);
  }

  &__headline {
    @include property-on-screens('margin-bottom', 15px, 20px, 1.2rem);
    text-align: center;
  }

  &__copy {
    text-align: center;
  }
}
