@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %subtext-regular;
}

.icon {
  width: 12px;
  height: 12px;

  @include on-screen(large) {
    width: 0.8rem;
    height: 0.8rem;
  }
}

.options {
  display: inline-flex;

  @include on-screen(medium) {
    display: flex;
  }

  & > * {
    @include on-screen(medium) {
      margin-right: 10px;
    }
  }
}

.label {
  display: inline;
  margin-right: 5px;

  @include on-screen(medium) {
    display: block;
  }
}

.hide-mobile {
  display: none;

  @include on-screen(medium) {
    display: inline;
  }
}
