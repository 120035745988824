@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: var(--s--spacer-large);
  margin-bottom: var(--s--spacer-large);

  @media (min-width: 1921px) {
    max-width: 100%;
    margin-right: calc(var(--s--wrap) + var(--s--sidebar-width)) !important;
    margin-left: var(--s--wrap) !important;
  }
}

.title-container {
  grid-column: 1/-1;
  @include property-on-screens('padding-right', 0, 3rem, 3rem);

  @include on-screen(large) {
    grid-column: 1 / span 5;
  }

  @media (min-width: 1100px) {
    grid-column: 2 / span 5;
  }

  @media (min-width: 1921px) {
    grid-column: 3 / span 5;
  }
}

.title {
  @include property-on-screens('margin-bottom', 2.25rem, 3rem, 4.5rem);

  @include on-screen(large) {
    margin-top: 7.5rem;
  }

  @media (min-width: 1921px) {
    margin-top: 9rem;
  }
}

.social-media-bar {
  @include property-on-screens('margin-bottom', 2.25rem, 90px, 0);
}

.tile-container {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(2, 1fr);
  @include property-on-screens('grid-gap', 4px, 8px, 10px);
  margin-right: calc(var(--s--wrap) * -1);

  @include on-screen(large) {
    grid-column: 6 / span 7;
    grid-template-columns: repeat(11, 1fr);
  }

  @media (min-width: 1100px) {
    grid-column: 7 / span 6;
  }

  @media (min-width: 1921px) {
    grid-column: 8 / span 5;
  }

  .tile__image {
    grid-column: span 3;
    width: 100%;
    height: 100%;

    @include on-screen(large) {
      grid-column: span 5;
    }

    &.cutted {
      grid-column: span 1;

      img {
        object-position: left;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tile {
  display: flex;
  grid-column: span 3;
  flex-direction: column;
  background-color: var(--c--cosy-cashmere);
  color: var(--c--smart-blue);

  @include on-screen(large) {
    grid-column: span 5;
  }

  &__title {
    flex: 1;
    padding: 1rem 1.25rem 0;
    @include property-on-screens('font-size', 1.1rem, 2rem, 2.75rem);

    @include on-screen(medium) {
      padding: 2.5rem 3.125rem 0;
    }
  }

  p {
    display: none;

    @include on-screen(medium) {
      display: block;
      padding: 0 3.125rem 3.125rem;
    }
  }
}
