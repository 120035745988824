@import './libs/veneer/src/styles/sass/application';

$button-margin: (
  'small': 25px,
  'medium': 30px,
  'large': 55px,
);

.modal {
  position: fixed;
  inset: 0;
  z-index: 9;
  background: var(--c--light-grey);
  overflow: auto;
}

.description-space {
  margin-bottom: 1rem;
}

.close {
  position: absolute;
  z-index: 2;
  top: 12px;

  @include on-screen('medium') {
    top: 37px;
  }

  @include on-screen('large') {
    top: 1rem;
  }

  @each $screen, $size in $button-margin {
    @include on-screen($screen) {
      right: $size;
    }
  }
}

.control {
  position: absolute;
  z-index: 2;
  top: 50%;

  &--prev {
    @each $screen, $size in $button-margin {
      @include on-screen($screen) {
        left: $size;
      }
    }
  }

  &--next {
    @each $screen, $size in $button-margin {
      @include on-screen($screen) {
        right: $size;
      }
    }
  }
}

.inner {
  min-height: 100%;
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
  }
}

.inner {
  padding: 1.5rem 2rem;

  @include on-screen('large') {
    padding: 4rem 11.5rem;
  }
}

.slide {
  width: 100%;
  height: 100%;
}

.media {
  $self: &;

  &,
  &__item {
    width: 100%;
    height: 100%;
  }

  &__item {
    display: block;
    object-fit: contain;
    object-position: center;
  }

  &__youtube {
    height: 100%;
  }

  &--video {
    display: flex;
    align-items: center;

    #{$self}__item {
      height: auto;
    }
  }

  &--youtube {
    #{$self}__item {
      display: flex;
      align-items: center;
    }
  }
}
