@import '../../../styles/sass/application';

.container {
  display: block;
  @extend %text-short-light;
  cursor: pointer;

  &:hover .title {
    color: var(--c--smart-blue);
  }
}

.title {
  padding-bottom: 0.2rem;
  @extend %text-short-medium;
}

.excerpt {
  em {
    background-color: var(--c--light-grey);
    font-style: normal;
  }
}

.path {
  padding-top: 0.2rem;
  color: var(--c--smart-blue);
}
