@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;

  position: relative;
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-medium);
}

.inner {
  @include columns-on-screens(span 6, 2 / span 4, 3 / span 8);
}

.thumbs {
  position: relative;
  margin-top: 2.5rem;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: -2px;
    bottom: -2px;
    width: 48px;
    pointer-events: none;

    @include on-screen(large) {
      width: 5rem;
    }
  }

  &::before {
    left: -2px;
    background: linear-gradient(90deg, var(--c--white) 15%, rgba(var(--c--white-rgb), 0) 100%);
  }

  &::after {
    right: -2px;
    background: linear-gradient(270deg, var(--c--white) 15%, rgba(var(--c--white-rgb), 0) 100%);
  }

  &__button {
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);

    svg {
      @include property-on-screens('width', 24px, 24px, 2.3rem);
    }

    &--prev {
      @include property-on-screens('left', -4px, -5px, -0.5rem);
    }

    &--next {
      @include property-on-screens('right', -4px, -5px, -0.5rem);
    }
  }
}
