@import 'mixins/screen';

@import 'reset';

@import '../storybook-css/webfont.css';

@import 'variables';
@import 'typo';
@import 'grid';
@import 'text-form';
@import 'text-headings';
@import 'text-bodytext';
@import '~swiper/swiper.min.css';

html {
  height: 100%;
  color: var(--c--intense-blue);
  font-family: 'Noto Sans', 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;

  @include on-screen(medium) {
    font-size: 17px;
  }

  @include on-screen(large) {
    font-size: 14px;
  }

  // to be tested: new breakpoints for better scaling
  @media (min-width: 1350px) {
    font-size: 14px;
  }

  @media (min-width: 1580px) {
    font-size: 16px;
  }

  @media (min-width: 1800px) {
    font-size: 20px;
  }
}

body.fixed,
html.fixed {
  overflow: hidden;
  height: 100%;
}
