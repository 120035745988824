@import '../../../styles/sass/application';

.container {
  display: flex;
  flex-direction: column;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.input-container {
  position: relative;
  flex: 1 1 auto;
}

.select {
  width: 100%;
  min-width: 200px;
  height: var(--s--ui-element-height);
  @include property-on-screens('padding', 0 58px 0 20px, 0 58px 0 20px, 0 3rem 0 20px);
  border: 0;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  @extend %regular;
  color: var(--c--intense-blue);
  font-size: var(--fs--interaction);
  line-height: 1.5;
  cursor: pointer;

  &--round {
    border-radius: calc(var(--s--ui-element-height) / 2);
  }

  &--white {
    background-color: var(--c--white);
  }

  &--light-grey {
    background-color: var(--c--light-grey);
  }
}

.decorator {
  position: absolute;
  z-index: 1;
  top: 50%;
  @include property-on-screens('right', 30px, 30px, 1.5rem);
  @include property-on-screens('width', 13px, 13px, 0.65rem);
  @include property-on-screens('height', 14px, 14px, 0.7rem);
  overflow: hidden;
  transform: translateY(-50%);
  line-height: 1;
  pointer-events: none;

  &__helper {
    position: absolute;
    top: 0;
    left: 0;
    @include property-on-screens('width', 13px, 13px, 0.65rem);
    @include property-on-screens('height', 14px, 14px, 0.7rem);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    svg {
      width: 100%;
      height: 100%;
    }
    svg path {
      stroke-width: 3 !important;
    }
  }
}
