@import './libs/veneer/src/styles/sass/application';

.container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.nav {
  display: block;
  position: absolute;
  z-index: 1;
  bottom: 13px;
  width: 100%;

  @include on-screen(medium) {
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 13px;
    width: 100%;
  }

  @include on-screen(large) {
    bottom: 0;
  }

  &__inner {
    @extend %wrapped-content;

    display: flex;
    gap: 1px;
    justify-content: center;
    margin-right: 23px;
    margin-left: 23px;

    @include on-screen(medium) {
      gap: 7px;
      margin-right: 17px;
      margin-left: 17px;
    }

    @include on-screen(large) {
      gap: 1px;
      justify-content: flex-end;
      margin-right: calc(var(--s--wrap) + var(--s--sidebar-width));
      margin-left: var(--s--wrap);
    }
  }
}

.nav-item {
  width: 240px;
  padding: 22px 24px;
  background-color: rgba(var(--c--smart-blue-rgb), 0.7);
  color: var(--c--brilliant-blue);

  @include below-screen(medium) {
    padding: 6px 8px 11px;
  }

  @include on-screen(large) {
    width: 14rem;
  }

  &__title {
    display: flex;
    justify-content: space-between;

    @include below-screen(medium) {
      flex-direction: column;
      height: 100%;

      &--text {
        margin-bottom: 17px;
        font-size: 14px;
      }
    }

    @include on-screen(medium) {
      margin-bottom: 6px;
    }

    @include on-screen(large) {
      margin-bottom: 0.4rem;
    }


  }

  &__controls {
    flex: 0 0 auto;

    @include below-screen(medium) {
      margin-top: auto;
    }
  }

  &__icon {
    width: 1.35rem;
  }

  &--active {
    background-color: var(--c--feelgood-mint);
    color: var(--c--smart-blue);
  }
}

.media {
  position: absolute;
  inset: 0;

  &__item {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
