@import '../../../../veneer/src/styles/sass/application';

.container {
  @extend %grid;
  margin-top: var(--s--spacer-small);
  margin-bottom: var(--s--spacer-medium);
}

.headline {
  grid-column: 1/-1;
  @include property-on-screens('margin-bottom', 10px, 10px, 0);
}

.items {
  display: grid;
  grid-column: 1/-1;
  grid-gap: var(--s--grid-gap);
  grid-template-columns: repeat(12, minmax(0, 1fr));

  @include on-screen(medium) {
    row-gap: calc(var(--s--grid-gap) / 2);
  }

  > div {
    grid-column: 1/-1;

    @include on-screen(large) {
      grid-column: span 6;
    }
  }
}
