@import '../../../styles/sass/application';

@keyframes upDownArrow {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -23%);
  }
}

.row-item {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 18px 22px 25px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: 0;
    background-color: rgba(#000, 0.05);
    transition: max-height 0.5s ease-in-out;
  }

  @include on-screen(medium) {
    flex-direction: row;
    align-items: flex-end;
    padding: 60px 24px 20px;
    column-gap: 35px;
  }

  @include on-screen(large) {
    padding: 3rem 2rem 1.7rem;
    column-gap: 6.5rem;
  }

  &__top {
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;

    @include on-screen(medium) {
      flex: 1;
      flex-basis: 62%;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    @include on-screen(large) {
      flex-basis: 70%;
    }
  }

  &__bottom {
    display: flex;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 0.5rem;

    @include on-screen(medium) {
      flex-basis: 38%;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    @include on-screen(large) {
      flex-basis: 30%;
    }
  }

  &__title {
    @extend %text-short-medium;
    text-decoration: none;
    cursor: pointer;

    @include on-screen(medium) {
      font-weight: 300;
    }
  }

  &__add-to-wishlist {
    @include property-on-screens('margin-bottom', 0, 0, -0.2rem);
  }

  &__additional-information,
  &__label {
    @extend %subtext;
  }

  &__icon {
    &--download {
      width: 50px;
      height: 50px;

      @include on-screen(medium) {
        margin-bottom: -6px;
      }

      @include on-screen(large) {
        margin-bottom: -0.5rem;
      }
    }

    &--link {
      @include property-on-screens('width', 32px, 32px, 41px);
      @include property-on-screens('height', 32px, 32px, 41px);
    }
  }

  &__link {
    text-align: right;
    flex-grow: 1;

    @include on-screen(medium) {
      flex-grow: initial;
    }
  }

  &--download {
    background-color: var(--c--light-grey);
  }

  &--vacancy {
    background-color: rgba(#fff, 0.2);
    cursor: pointer;

    &:after {
      background-color: rgba(#fff, 0.2);
    }

    .row-item__meta {
      @include property-on-screens('margin-bottom', -10px, 0, 0);
    }

    .row-item__title {
      @include property-on-screens('margin-bottom', 74px, 0, 0);
    }

    .row-item__additional-information,
    .row-item__label {
      @include below-screen(medium) {
        @include text-short;
      }
    }
  }

  &:hover {
    .download-arrow-animate {
      animation: 0.5s upDownArrow var(--ease-in-out-cubic) 0.3s;
    }

    &:after {
      max-height: 100%;
      transition-delay: 0.3s;
    }
  }
}
