@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;

  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-medium);

  @include on-screen(large) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
