@import '../../../../../../libs/veneer/src/styles/sass/application';

.link {
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;

  @include on-screen(medium) {
    flex-direction: column;
    gap: 0;
  }

  &__image {
    display: block;
    position: relative;
    aspect-ratio: 1.4;
    width: 24%;

    .image {
      @include image-cover;
    }

    @include on-screen(medium) {
      width: 85%;
    }
  }

  &__label {
    width: 76%;
    @extend %text-short-medium;
    text-align: left;
    white-space: nowrap;

    @include on-screen(medium) {
      width: 100%;
      margin-top: 0.35rem;
      text-align: center;
    }
  }

  &--clicked {
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      width: 2rem;
      height: 2rem;
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: var(--c--middle-grey);
      background: #fff;
      transform: translate(-50%, 0) rotate(45deg);
      opacity: 1;

      @include property-on-screens(top, 1px, calc(100% + 1.05rem), calc(100% + 1.4rem));
    }
  }
}
