@import './libs/veneer/src/styles/sass/application';

.container {
  text-align: center;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
  border-radius: 50%;
  background-color: var(--c--light-grey);

  @include on-screen(medium) {
    width: 149px;
    height: 149px;
    margin-bottom: 38px;
  }

  @include on-screen(large) {
    width: 8.05rem;
    height: 8.05rem;
  }
}

.icon {
  width: 18px;

  @include on-screen(medium) {
    width: 24px;
  }

  @include on-screen(large) {
    width: 1.3rem;
  }
}

.headline,
.subline {
  display: block;
}

.inner-text {
  margin-bottom: 24px;

  @include on-screen(medium) {
    margin-bottom: 43px;
  }
}
