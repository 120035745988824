@import './libs/veneer/src/styles/sass/application';

.container {
  @extend %grid;

  display: block;
  margin-top: var(--s--spacer-medium);
  margin-bottom: var(--s--spacer-medium);

  @include below-screen(large) {
    margin-right: 0;
    margin-left: 0;
  }
}
