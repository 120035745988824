@import './libs/veneer/src/styles/sass/application';

.line {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  text-align: center;
  gap: 10px;

  @include on-screen(large) {
    gap: 1rem;
  }

  &--with-divider {
    justify-content: flex-start;
    text-align: left;
    white-space: nowrap;
  }
}

.word {
  flex: 0 1 auto;
}

.divider {
  display: inline-block;
  flex: 1;
  @include property-on-screens('height', 1px, 2px, 3px);
  background-color: var(--c--feelgood-mint);
}
